import React from 'react';
import {apiGet, apiPut, apiPost, apiDelete} from './api';
import {
  List,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Button
} from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Grid} from '@material-ui/core';
import {nullZLS, nullZLSTabella} from './dati.js';
import moment from 'moment';
import RapportiniRighe from './RapportiniRighe';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {IconButton} from '@material-ui/core';
import {Magnify} from 'mdi-material-ui'
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles.js';
import Paper from '@material-ui/core/Paper';
import ReactList from 'react-list';

const drawerWidth = 250;

class Rapportini extends React.Component {
  state = {
    rapportini_elenco: [],
    rapportino_original: {},
    rapportino: {
      id: '',
      data: '',
      oraInizio: '',
      oraFine: '',
      cliente: null,
      tecnico: null,
      note: '',
      notePrivate: '',
      trasferta: false,
      km: '',
      pedaggio: '',
      costiExtra: '',
      descrizioneCostiExtra: ''
    },
    righe: [],
    righe_original: [],
    filtro: {
      dataDA: '',
      dataA: '',
      cliente: null,
      tecnico: null,
      descrizioneRighe: ''
    },
    anagrafiche: [],
    utenti: [],
    btnSave: false
  }

  caricaElencoRapportini() {
		var filtro = JSON.stringify(this.state.filtro);
		var url = "/rapportini/elenco2/"+filtro;
    apiGet(url, data => {
      if (data.length > 50) {
        data[50].descrizioneCliente = "Ci sono troppi risultati";
        data[50].descrizioneTecnico = "Metti un filtro";
        data[50].id = null;
      }
      this.setState({rapportini_elenco: data});
    });
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.apriDettagli(id);
    apiGet("/anagrafiche/rapportini/elenco", (data) => {
      if (data.length === 0)
        return;
      var anagrafiche = data.map(c => {
        return {value: c.id, label: c.denominazione};
      });
      this.setState({anagrafiche: anagrafiche});
    });

    apiGet("/utenti", (data) => {
      if (data.length === 0)
        return;
      var utenti = data.map(c => {
        return {value: c.id, label: c.nome+" "+c.cognome, ruolo: c.ruolo, username: c.username};
      });
      this.setState({utenti: utenti});
    });
    const filtro = nullZLS(this.state.filtro);
    const oggi = new Date();
		var aFormat = new moment(oggi).format('YYYY-MM-DD');

		var dataDA = moment().subtract(3, 'month');
    var daFormat = new moment(dataDA).format('YYYY-MM-DD')

    filtro.dataDA = daFormat;
    filtro.dataA = aFormat;
    this.caricaElencoRapportini();
  }

  handleDuplicaRapportino = () => {
    var rapportinoDuplicato = this.state.rapportino;
    var righeDuplicate = nullZLSTabella(this.state.righe);
    delete this.state.rapportino;
    delete this.state.righe;
    this.duplicaRapportino(rapportinoDuplicato, righeDuplicate);
  }

  handleStampaRapportino = () => {
    var id = this.state.rapportino.id;
    apiGet("/rapportini/" + id + "/pdf?pdf=1");
    /*
		var adesso = new Date();
		var rapportinoFine = new Date(this.state.rapportino.data + " " + this.state.rapportino.oraFine);
		var msDiff = rapportinoFine.getTime() - adesso.getTime();
		if (msDiff < 20*60000) {
			apiGet("/rapportini/" + id + "/pdf");
		} else {
			if (msDiff < 80*60000) {
				alert("Che cosa fai? Non puoi stampare il rapportino prima del tempo!");
				alert("Hai un valido motivo per stamparlo così presto?");
				alert("Allora?!? Devi davvero stamparlo?");
				alert("Rendi conto del motivo. Adesso!");
				apiGet("/rapportini/" + id + "/pdf");
			} else {
				alert("Che cosa fai? Non puoi stampare il rapportino prima del tempo!");
			}
		}
		*/
  }

  handleMailRapportino = () => {
    var id = this.state.rapportino.id;
    apiGet("/rapportini/" + id + "/pdf?pdf=0");
    alert("Email inviate.");
  }

  handleAutocompleteClienteChange = (event, value, reason) => {
    var rapportino=this.state.rapportino;
    rapportino.cliente=value.value;
    this.setState({rapportino:rapportino});
    this.handleClienteChange();
  }

  handleAutocompleteTecnicoChange = (event, value, reason) => {
    var rapportino=this.state.rapportino;
    rapportino.tecnico=value.value;
    this.setState({rapportino:rapportino});
  }

  handleFiltroAutocompleteClienteChange = (event, value, reason) => {
    var filtro=this.state.filtro;
    filtro.cliente=value;
    this.setState({cliente:filtro});
  }

  handleFiltroAutocompleteTecnicoChange = (event, value, reason) => {
    var filtro=this.state.filtro;
    filtro.tecnico=value;
    this.setState({tecnico:filtro});
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var rapportino = this.state.rapportino;
    rapportino[name] = value;
    this.setState({rapportino: rapportino});
  }

  handleInputChangeFiltro = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var filtro = this.state.filtro;
    filtro[name] = value;
    this.setState({filtro: filtro});
  }

  handleRigheChange = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var righe = this.state.righe.slice(0);
    for (var i = 0; i < righe.length; i++) {
      if (righe[i].id === riga.id) {
        righe[i][name] = value;
        this.setState({righe: righe});
        return;
      }
    }
  }

  handleCancel = () => {
    var rapportino_original = Object.assign({}, this.state.rapportino_original);
    this.setState({rapportino: rapportino_original});
  }

  handleSave = () => {
    var rapportino = {
      id: nullZLS(this.state.rapportino.id),
      data: nullZLS(this.state.rapportino.data),
      oraInizio: nullZLS(this.state.rapportino.oraInizio),
      oraFine: nullZLS(this.state.rapportino.oraFine),
      cliente: nullZLS(this.state.rapportino.cliente),
      tecnico: nullZLS(this.state.rapportino.tecnico),
      note: nullZLS(this.state.rapportino.note),
      notePrivate: nullZLS(this.state.rapportino.notePrivate),
      trasferta: nullZLS(this.state.rapportino.trasferta),
      km: nullZLS(this.state.rapportino.km),
      pedaggio: nullZLS(this.state.rapportino.pedaggio),
      costiExtra: nullZLS(this.state.rapportino.costiExtra),
      descrizioneCostiExtra: nullZLS(this.state.rapportino.descrizioneCostiExtra)
    };

    this.setState({rapportino: rapportino});

    if (rapportino.id === "new") {
      if (!rapportino.cliente) {
        alert('ERRORE inserire un cliente!');
        return;
      }
      if (!rapportino.tecnico) {
        alert('ERRORE inserire un tecnico!');
        return;
      }
      /*
			if (!rapportino.oraInizio) {
				alert('ERRORE inserire un ora inizio!');
				return;
			}
			if (!rapportino.oraFine) {
				alert('ERRORE inserire un ora fine!');
				return;
			}
			*/
      this.setState({btnSave: true});
      rapportino.id = null;
      apiPost("/rapportini", JSON.stringify(rapportino), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
          this.setState({btnSave: false});
        } else {
          var righe = this.state.righe;
          var idRapportino = parseInt(data, 10);
          righe.forEach(riga => {
            riga.rapportino = parseInt(data, 10);
            if (!riga.descrizione) {
              alert('ERRORE è stata insetira una riga vuota! Il rapportino verrà salvato con righe vuote.');
              this.caricaElencoRapportini();
              this.setState({btnSave: false});
              return;
            }
            this.setState({righe: righe});
            this.setState({btnSave: false});
            return;
          })
          let righeNULL = nullZLSTabella(this.state.righe);
          apiPost("/rapportini/righe", JSON.stringify(righeNULL), (data) => {
            alert("Rapportino aggiunto");
            this.apriDettagli(idRapportino);
            this.caricaElencoRapportini();
            this.setState({btnSave: false});
          });
        }
      });
    } else {
      apiPut("/rapportini", JSON.stringify(rapportino), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
        } else {
          var righe = this.state.righe;
          righe.forEach(riga => {
            if (!riga.descrizione) {
              alert('ERRORE è stata insetira una riga vuota! Il rapportino verrà salvato con righe vuote.');
              this.caricaElencoRapportini();
              this.setState({btnSave: false});
              return;
            }
            this.setState({righe: righe});
            this.setState({btnSave: false});
            return;
          })
          let righeNULL = nullZLSTabella(this.state.righe);
          apiPost("/rapportini/righe", JSON.stringify(righeNULL), (data) => {
            alert("Rapportino salvato");
            this.caricaElencoRapportini();
            this.setState({btnSave: false});
          });
        }
      });
    }
  }

  handleDelete = () => {
    if (!window.confirm("Confermare l'eliminazione del rapportino e delle sue righe."))
      return;
    apiDelete("/rapportini/" + this.state.rapportino.id, data => {
      apiDelete("/rapportini/" + this.state.rapportino.id + "/righe", data => {
        this.caricaElencoRapportini();
        this.props.history.push('/rapportini');
      });
    });
  }

  eliminaRiga = id => {
    if (!window.confirm("Confermare l'eliminazione della riga?"))
      return;
    var righe = this.state.righe;
    righe.forEach(riga => {
      if (riga.id === id) {
        riga.azione = "d";
        this.setState({righe: righe});
        return;
      }
    })
  }

  aggiungiRiga = () => {
    var righe = this.state.righe;
    var newid = 0;
    for (var i = 0; i < righe.length; i++) {
      const id = parseInt(righe[i].id, 10);
      if (id >= newid)
        newid = id + 1;
      }
    righe.push({
      azione: 'n',
      id: newid,
      rapportino: this.state.rapportino.id,
      utente: '',
      apparato: '',
      descrizione: '',
      esito: '1',
      notePrivate: ''
    });
    this.setState({righe: righe});
  }

  aggiungiRapportino() {
    var oggi = new Date();
    var oggiFormat = new moment(oggi).format('YYYY-MM-DD')
    var tecnicoUser = localStorage.getItem("username");
    var tecnicoID = '';
    this.state.utenti.map(u => {
      if (tecnicoUser === u.username)
        return tecnicoID = u.value;
      return tecnicoID;
    });
    var rapportinoNuovo = {
      id: 'new',
      data: oggiFormat,
      oraInizio: '',
      oraFine: '',
      cliente: null,
      tecnico: tecnicoID,
      note: '',
      notePrivate: '',
      trasferta: false,
      km: '',
      pedaggio: '',
      costiExtra: '',
      descrizioneCostiExtra: ''
    }
    this.setState({
      rapportino: rapportinoNuovo,
      righe: []
    }, () => {
      this.dettaglioRapportino();
    });
  }

  duplicaRapportino(rapportino, righe) {
    var oggi = new Date();
    var oggiFormat = new moment(oggi).format('YYYY-MM-DD')
    var tecnicoUser = localStorage.getItem("username");
    var tecnicoID = '';
    this.state.utenti.map(u => {
      if (tecnicoUser === u.username)
        return tecnicoID = u.value;
      return tecnicoID;
    });
    var rapportinoDuplicato = {
      id: 'new',
      data: oggiFormat,
      oraInizio: rapportino.oraInizio,
      oraFine: rapportino.oraFine,
      cliente: rapportino.cliente,
      tecnico: tecnicoID,
      note: rapportino.note,
      notePrivate: rapportino.notePrivate,
      trasferta: rapportino.trasferta,
      km: rapportino.km,
      pedaggio: rapportino.pedaggio,
      costiExtra: rapportino.costiExtra,
      descrizioneCostiExtra: rapportino.descrizioneCostiExtra
    }
    righe.map((r, index) => {
      r.id = index;
      r.azione = 'n';
      return r;
    });

    this.setState({
      rapportino: rapportinoDuplicato,
      righe: righe
    }, () => {
      this.dettaglioRapportino();
    });
  }

  aggiungiNuovo = () => {
    this.aggiungiRapportino();
  }

  apriDettagli = (id) => {
    if ((id === 'new') || (id === undefined)) {
      var rapportinoNuovo = {
        id: id,
        data: '',
        oraInizio: '',
        oraFine: '',
        cliente: null,
        tecnico: null,
        note: '',
        notePrivate: '',
        trasferta: false,
        km: '',
        pedaggio: '',
        costiExtra: '',
        descrizioneCostiExtra: ''
      }
      this.setState({rapportino: rapportinoNuovo})
    } else {
      apiGet("/rapportini/" + id, (data) => {
        if (data.length === 0) {
          alert("Rapportino non trovato");
          return;
        }
        var rapportino = data[0];

        Object.keys(rapportino).map(function(key, index) {
          rapportino[key] = rapportino[key] || '';
          return true;
        });
        rapportino.trasferta= (rapportino.trasferta==="1");
        var rapportino_original = Object.assign({}, rapportino);
        this.setState({rapportino: rapportino, rapportino_original: rapportino_original});
      });
      this.setState({righe: []});
      apiGet("/rapportini/" + id + "/righe", (data) => {
        if (data.length === 0)
          return;
        this.setState({righe: data});
      });
    }
  }

  rapportiniFiltrati = () => {
    var f = this.state.filtro;
    return this.state.rapportini_elenco.filter(d => {
      var r = true;
      var rapportini = d.descrizioneCliente + d.descrizioneTecnico;
      if (f.filtro_ricerca)
        r = r && ((rapportini).toLowerCase().includes(f.filtro_ricerca.toLowerCase()));
      return r;
    });
  }

  pulsanteElimina = () => {
    /*
		if (this.state.tecnico === true) {
			return (
				<div></div>
			);
		} else {
			return(
				<Button variant="contained" className={this.props.classes.elimina} fullWidth={true} onClick={this.handleDelete} >ELIMINA</Button>
			);
		}
		*/
  }

  rapportiniElencoItem = (index, key) => {
    const r = this.state.rapportini_elenco[index];
    return (<ListItem button key={r.id} onClick={() => {
        this.apriDettagli(r.id)
      }}>
      <ListItemText primary={new moment(r.data).format('DD/MM/YYYY')} secondary={r.descrizioneCliente + " - " + r.descrizioneTecnico}/>
    </ListItem>);
  }

  handleClienteChange = () => {
    var cliente = this.state.rapportino.cliente;
    apiGet("/anagrafiche/" + cliente, (data) => {
      var indirizzo = (data[0].indirizzoMerce!=="" ? data[0].indirizzoMerce : data[0].indirizzoSedeLegale);
      apiGet("/anagrafiche/" + cliente + "/indirizzi?id=" + indirizzo, (dataIndirizzo) => {
        var rapportino = this.state.rapportino;
        var km = dataIndirizzo[0].km;
        var pedaggio = dataIndirizzo[0].pedaggio;
        rapportino.km = km*2;
        rapportino.pedaggio = pedaggio*2;
      });
    });
  }

  dettaglioRapportino = () => {
    var cliente = this.state.anagrafiche.filter( a=>{ return a.value===this.state.rapportino.cliente } );
    if (cliente.length===0) {
      cliente=null;
    } else {
      cliente=cliente[0]
    }
    var tecnico = this.state.utenti.filter( a=>{ return a.value===this.state.rapportino.tecnico } );
    if (tecnico.length===0) {
      tecnico=null;
    } else {
      tecnico=tecnico[0]
    }


    if ((this.state.rapportino.id) || (this.state.rapportino.id === "new")) {
      return (<form autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <FormGroup>
              <TextField name="id" label="ID" value={this.state.rapportino.id} disabled={true} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <Autocomplete
                name="cliente"
                options={ this.state.anagrafiche }
                getOptionLabel={ (option) => option.label }
                value={ cliente }
                onChange={ this.handleAutocompleteClienteChange }
                autoComplete
                includeInputInList
                renderInput={ (params) => <TextField {...params} label="Cliente"/> }
              />
              </FormGroup>
          </Grid>
          <Grid item xs={5}>
            <FormGroup>
              <Autocomplete
                name="tecnico"
                options={ this.state.utenti }
                getOptionLabel={ (option) => option.label }
                onChange={ this.handleAutocompleteTecnicoChange }
                value={ tecnico }
                autoComplete
                includeInputInList
                renderInput={ (params) => <TextField {...params} label="Tecnico"/> }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormGroup>
              <TextField name="data" label="Data rapportino" value={this.state.rapportino.data} placeholder="" fullWidth margin="dense" type="date" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField name="oraInizio" label="Ora inizio" value={this.state.rapportino.oraInizio} placeholder="" fullWidth margin="dense" type="time" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField name="oraFine" label="Ora fine" value={this.state.rapportino.oraFine} placeholder="" fullWidth margin="dense" type="time" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
        </Grid>
        <br/>
        <RapportiniRighe righe={this.state.righe} onChange={this.handleRigheChange} aggiungiRiga={this.aggiungiRiga} eliminaRiga={this.eliminaRiga}/>
        <br/>
        <FormGroup>
          <TextField name="note" label="Note" value={this.state.rapportino.note} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
              shrink: true
            }}/>
        </FormGroup>
        <br/>
        <h3>NOTE ad uso interno</h3>
        <FormGroup>
          <TextField name="notePrivate" label="Note Private" value={this.state.rapportino.notePrivate} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
              shrink: true
            }}/>
        </FormGroup>
        <br/>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControlLabel
              name="trasferta"
              checked={ this.state.rapportino.trasferta }
              onChange={ this.handleInputChange }
              control={ <Switch color="primary" /> }
              label="Trasferta?"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField name="costiExtra" label="Costi EXTRA (in €)" value={this.state.rapportino.costiExtra} placeholder="" fullWidth margin="dense" type="number" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField name="descrizioneCostiExtra" label="Descrizione costi EXTRA" value={this.state.rapportino.descrizioneCostiExtra} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
        </Grid>
        <br/><br/>
        <Grid container spacing={3} style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            zIndex: 9999
          }}>
          <Grid item xs={2}>
            <Button variant="contained" className={this.props.classes.inserisci} fullWidth={true} onClick={this.handleDuplicaRapportino}>DUPLICA</Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleStampaRapportino}>STAMPA</Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleMailRapportino}>Invia mail</Button>
          </Grid>
          <Grid item xs={2}>
            {this.pulsanteElimina()}
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={this.handleCancel}>ANNULLA</Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" className={this.props.classes.salva} fullWidth={true} onClick={this.handleSave} disabled={this.state.btnSave}>SALVA</Button>
          </Grid>
        </Grid>
      </form>);
    }
    return (<div>
      <h2>Selezionare un rapportino a fianco.</h2>
    </div>)
  }

  render() {
    return (<div style={{
        display: 'flex'
      }}>
      <Drawer style={{
          width: drawerWidth,
          flexShrink: 0
        }} variant="permanent">
        <Grid container style={{
            paddingTop: 100,
            margin: 0,
            width: drawerWidth
          }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={9}>
            <Button disabled={this.props.modifica} variant="contained" className={this.props.classes.inserisci} style={{
                margin: 10
              }} fullWidth={true} onClick={() => {
                this.aggiungiNuovo(this.props.match.params.tipo)
              }}>INSERISCI</Button>
          </Grid>
          <Grid item xs={1}></Grid>

          <Accordion>
            <AccordionSummary id="ricerca-header">
              <Button variant="contained" className={this.props.classes.funzione} style={{
                  margin: 0
                }} fullWidth={true}>RICERCA</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container style={{
                  padding: 0,
                  margin: 0
                }}>
                <Grid item xs={12}>
                  <FormControl style={{
                      marginTop: 5
                    }} fullWidth>
                    <TextField name="dataDA" value={this.state.filtro.dataDA} label="Dalla data" placeholder="Data rapportino" InputLabelProps={{
                        shrink: true
                      }} type="date" onChange={this.handleInputChangeFiltro}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{
                      marginTop: 5
                    }} fullWidth>
                    <TextField name="dataA" value={this.state.filtro.dataA} label="alla data" placeholder="Data rapportino" InputLabelProps={{
                        shrink: true
                      }} type="date" onChange={this.handleInputChangeFiltro}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup style={{
                      marginTop: 5
                    }}>
                    <Autocomplete
                      name="cliente"
                      options={this.state.anagrafiche}
                      getOptionLabel={(option) => option.label}
                      onChange={this.handleFiltroAutocompleteClienteChange}
                      autoComplete
                      includeInputInList
                      value={ this.state.filtro.cliente }
                      renderInput={(params) => <TextField {...params} label="Cliente"/>}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup style={{
                      marginTop: 5
                    }}>
                    <Autocomplete
                      name="tecnico"
                      options={this.state.utenti}
                      getOptionLabel={(option) => option.label}
                      onChange={ this.handleFiltroAutocompleteTecnicoChange }
                      autoComplete
                      includeInputInList
                      value={ this.state.filtro.tecnico }
                      renderInput={(params) => <TextField {...params} label="Tecnico"/>}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={9}>
                  <FormControl style={ { marginTop:5 } } fullWidth>
                    <TextField id="descrizioneRighe" name="descrizioneRighe" value={this.state.filtro.descrizioneRighe} label="Descrizione riga" placeholder="Descrizione riga" InputLabelProps={{
                        shrink: true
                      }} type="text" onChange={this.handleInputChangeFiltro} />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <IconButton className={this.props.classes.funzione} size="small" variant="outlined" style={{
                      margin: 10
                    }} onClick={() => {
                      this.caricaElencoRapportini()
                    }}><Magnify/></IconButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Paper style={{/*height:'100vh',*/
            overflow: 'auto',
            width: drawerWidth
          }} elevation={0} square={true}>
          <List dense={true} disablePadding={true}>
            <ReactList style={{
                width: drawerWidth
              }} itemRenderer={this.rapportiniElencoItem} length={this.state.rapportini_elenco.length} type='uniform'/>
          </List>
        </Paper>
      </Drawer>
      <main style={{
          flexGrow: 1,
          padding: 5
        }}>
        <div>
          {this.dettaglioRapportino()}
        </div>
      </main>
    </div>);
  }
}

export default withStyles(styles)(Rapportini);
