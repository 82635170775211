import React from 'react';
import StatisticheFatturato from './StatisticheFatturato';
import StatisticheCliente from './StatisticheCliente';

export default class Statistiche extends React.Component {
	render ()	{
		return (
			<div>
				<h1>Statistiche</h1>
				<h2>Fatturato per mese</h2>
				<StatisticheFatturato tipo="mese" />
				<br/><br/>
				<hr/>
				<h2>Fatturato per mese cumulativo</h2>
				<StatisticheFatturato tipo="cumulativo" />
				<br/><br/>
				<hr/>
				<h2>Fatturato per cliente</h2>
				<StatisticheCliente  />
			</div>
		);
	}
}
