import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {List, ListItem} from '@material-ui/core';
import {Link} from "react-router-dom";
import {apiGet} from './api';
import {clearUser} from './auth';

const styles = theme => ({
  root: {
    "&$button": {
      width: "auto",
      display: "inline-block"
    },
    "&$selected": {
      color: "yellow"
    }
  },
  button: {},
  selected: {}
});

class Header extends React.Component {
  state = {
    menu: []
  };

  componentDidMount() {
    apiGet("/menu", (data) => {
      this.setState({menu: data});
    });
  }

  handleLogoff = () => {
    clearUser();
    window.location = "/login";
  };

  activePage = (url) => {
    if (url === window.location.pathname) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {classes} = this.props;

    return (<div>
      <List style={{
          backgroundColor: "#DD0019",
          color: "#FFFFFF"
        }}>
        <a href="/"><img src="/logo.png" style={{
        height: "50px",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "10px"
      }} alt="Sapsystems"/></a>
        {
          Array.isArray(this.state.menu) && this.state.menu.map(
            m => m.descrizione === "-"
            ? ""
            : <ListItem button style={{marginTop:-25}} classes={classes} component={Link} to={m.url || null} key={m.id} selected={this.activePage(m.url)}>
              {m.descrizione}
            </ListItem>)
        }
        <ListItem button classes={classes} style={{
            borderLeft: '0.005em solid white',
            padding: '0.5em',
            marginTop: -25
          }} onClick={this.handleLogoff}>
          Disconnessione
        </ListItem>
      </List>
    </div>);
  }
}

export default withStyles(styles)(Header);
