import React from 'react';
import {apiGet, apiPut} from './api';
import {
  InputLabel,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Pencil, Cancel, ContentSave, Check} from 'mdi-material-ui'
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles.js';
import {nullZLS} from './dati.js';
import {Magnify} from 'mdi-material-ui'

class Rimborsi extends React.Component {
  state = {
    filtro: {
      tecnico: null,
      dataRapportinoDa: '',
      dataRapportinoA: '',
    },
    utenti: [],
    rimborsi: [],
    rimborsiOriginali: [],
    totaleKM: 0,
    totaleRimborsiKM: 0,
    totalePedaggi: 0,
    totaleCostiEXTRA: 0,
    totaleRimborsi: 0,
    rimborsiEdit: {},
    fontSizeTable: 12
  }

  calcolaTotali = () => {
    var rimborsi = this.state.rimborsi;
    var totaleKM = 0;
    var totaleRimborsiKM = 0;
    var totalePedaggi = 0;
    var totaleCostiEXTRA = 0;
    var totaleRimborsi = 0;
    for (var i = 0; i < rimborsi.length; i++) {
      if ((rimborsi[i].trasferta==="1")) {
        var kmRiga = rimborsi[i].km ? rimborsi[i].km : "0";
        totaleKM += parseFloat(kmRiga);
        var rimborsiKMRiga = rimborsi[i].km * (rimborsi[i].rimborsoKM ? rimborsi[i].rimborsoKM : "0");
        totaleRimborsiKM += parseFloat(rimborsiKMRiga);
        var pedaggiRiga = rimborsi[i].pedaggio ? rimborsi[i].pedaggio : "0";
        totalePedaggi += parseFloat(pedaggiRiga);
        var costiEXTRARiga = rimborsi[i].costiExtra ? rimborsi[i].costiExtra : "0";
        totaleCostiEXTRA += parseFloat(costiEXTRARiga);
        var rimborsiRiga = parseFloat(rimborsiKMRiga) + parseFloat(pedaggiRiga) + parseFloat(costiEXTRARiga);
        totaleRimborsi += parseFloat(rimborsiRiga);
      }
    }
		this.setState({totaleKM: totaleKM.toFixed(2)});
		this.setState({totaleRimborsiKM: totaleRimborsiKM.toFixed(2)});
    this.setState({totalePedaggi: totalePedaggi.toFixed(2)});
		this.setState({totaleCostiEXTRA: totaleCostiEXTRA.toFixed(2)});
    this.setState({totaleRimborsi: totaleRimborsi.toFixed(2)});
  }

  caricaElencoRimborsi() {
    const filtro = nullZLS(this.state.filtro);
    if (!filtro.tecnico) {
      alert("Tecnico mancante");
      return;
    }
    if (!filtro.dataRapportinoDa) {
      alert("Data inizio mancante");
      return;
    }
    if (!filtro.dataRapportinoA) {
      alert("Data fine mancante");
      return;
    }
    var url = "/rapportini/rimborsi/" + filtro.tecnico.value + "/" + filtro.dataRapportinoDa + "/" + filtro.dataRapportinoA;
    apiGet(url, data => {
      this.setState({ rimborsi: data, rimborsiOriginali: data });
      this.calcolaTotali();
    });
  }

  componentDidMount() {
    apiGet("/utenti", (data) => {
      if (data.length === 0)
        return;
      var utenti = data.map(c => {
        return {value: c.id, label: c.nome+" "+c.cognome, rimborsoKM: c.rimborsoKM};
      });
      this.setState({utenti: utenti});
    });
    const filtro = nullZLS(this.state.filtro);
    // Primo giorno scorso mese
    var oggi = new Date();
    var inizioUltimoMese = oggi.setDate(1);
    inizioUltimoMese = oggi.setMonth(oggi.getMonth() - 1);
    var inizioUltimoMeseFormat = new moment(inizioUltimoMese).format('YYYY-MM-DD')
    // Ultimo giorno scorso mese
    oggi = new Date();
    var fineUltimoMese = oggi.setDate(0);
    var fineUltimoMeseFormat = new moment(fineUltimoMese).format('YYYY-MM-DD')
    // Inserisco i valori
    filtro.dataRapportinoDa = inizioUltimoMeseFormat;
    filtro.dataRapportinoA = fineUltimoMeseFormat;
  }

  handleInputChange = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var rimborsi = this.state.rimborsi;
    if (riga.id !== this.state.rimborsiEdit.id)
      return;
    for (var i = 0; i < rimborsi.length; i++) {
      if (rimborsi[i].id === riga.id) {
        rimborsi[i][name] = value;
        this.setState({rimborsiEdit: rimborsi[i]});
        this.setState({rimborsi: rimborsi});
        return;
      }
    }
  }

  handleInputChangeFiltro = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var filtro = this.state.filtro;
    filtro[name] = value;
    this.setState({filtro: filtro});
  }

  handleModifica = (row) => {
    this.setState({ rimborsiEdit: JSON.parse(JSON.stringify(row)) });
  }

  handleCancel = (id) => {
    var rimborsiOriginali = this.state.rimborsiOriginali;
    for (var i = 0; i < rimborsiOriginali.length; i++) {
      if (rimborsiOriginali[i].id === this.state.rimborsiEdit.id) {
        rimborsiOriginali[i] = JSON.parse(JSON.stringify(this.state.rimborsiEdit));
        this.setState({rimborsi: rimborsiOriginali, rimborsiEdit: {}});
        this.calcolaTotali();
        break;
      }
    }
  }

  handleSave = () => {
    var rimborso = {};
    const rimborsiEdit = this.state.rimborsiEdit;
    rimborso.id = rimborsiEdit.id;
    rimborso.costiExtra = nullZLS(rimborsiEdit.costiExtra);
    rimborso.descrizioneCostiExtra = nullZLS(rimborsiEdit.descrizioneCostiExtra);
    rimborso.trasferta = nullZLS(rimborsiEdit.trasferta);
    apiPut("/rapportini/rimborsi", JSON.stringify(rimborso), data => {
      this.setState({rimborsiEdit: {}});
      this.calcolaTotali();
    });
  }

  handleFiltroAutocompleteTecnicoChange = (event, value, reason) => {
    var filtro=this.state.filtro;
    filtro.tecnico=value;
    this.setState({filtro:filtro});
  }

  render() {
    return (<div style={{
        display: 'flex'
      }}>
      <main style={{
          flexGrow: 1
        }}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Autocomplete
              name="tecnico"
              options={ this.state.utenti }
              getOptionLabel={ (option) => option.label }
              onChange={ this.handleFiltroAutocompleteTecnicoChange }
              value={ this.state.filtro.tecnico }
              autoComplete
              includeInputInList
              renderInput={ (params) => <TextField {...params} label="Tecnico"/> }
            />
          </Grid>
          <Grid item xs={2}>
            <InputLabel htmlFor="dataRapportinoDa">Data da</InputLabel>
            <TextField id="dataRapportinoDa" name="dataRapportinoDa" InputLabelProps={{
                shrink: true
              }} value={this.state.filtro.dataRapportinoDa} type="date" InputProps={{
                style: {
                  fontSize: '14px'
                }
              }} onChange={this.handleInputChangeFiltro}/>
          </Grid>
          <Grid item xs={2}>
            <InputLabel htmlFor="dataRapportinoA">Data a</InputLabel>
            <TextField id="dataRapportinoA" name="dataRapportinoA" InputLabelProps={{
                shrink: true
              }} value={this.state.filtro.dataRapportinoA} type="date" InputProps={{
                style: {
                  fontSize: '14px'
                }
              }} onChange={this.handleInputChangeFiltro}/>
          </Grid>
          <Grid item xs={1}>
            <IconButton className={this.props.classes.funzione} size="small" variant="outlined" style={{
                margin: 10
              }} onClick={() => {
                this.caricaElencoRimborsi()
              }}><Magnify/></IconButton>
          </Grid>
        </Grid>
        <br/><br/>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Tecnico</TableCell>
              <TableCell align="left">Data ed ora</TableCell>
              <TableCell align="left">KM</TableCell>
              <TableCell align="left">Tot. €/KM</TableCell>
              <TableCell align="left">Pedaggio</TableCell>
              <TableCell align="left">€ Extra</TableCell>
              <TableCell align="left">Descr. costo EXTRA</TableCell>
              <TableCell align="left">Trasferta</TableCell>
              <TableCell align="left">Totale</TableCell>
							<TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
		            {this.state.rimborsi.map( (row, index) => (
		              <TableRow key={row.id} style={{ height: '25px' }}>
		                <TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
											{ row.id }
										</TableCell>
		                <TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
											{ row.denominazione }
										</TableCell>
		                <TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
											{ row.descrizioneTecnico }
										</TableCell>
		                <TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
											{ (row.data==="") ? "" : new moment(row.data).format('DD/MM/YYYY') }<br/>
                      { (row.oraInizio).substring(0, 5)+" - "+(row.oraFine).substring(0, 5) }
										</TableCell>
										<TableCell align="right" style={{ fontSize: this.state.fontSizeTable }}>
                      { (this.state.rimborsiEdit.id!==row.id) && (row.trasferta==="1") ? ( (row.km) ? row.km+" ("+(row.km/2).toFixed(2)+"x2)" : "" ) : "" }
  										{ (this.state.rimborsiEdit.id===row.id) && <TextField id="km" name="km" value={ row.km ? row.km : "" } type="number" onChange={ (e) => this.handleInputChange(e, row) } InputProps={{ style: { fontSize: '10px', } }} /> }
										</TableCell>
										<TableCell align="right" style={{ fontSize: this.state.fontSizeTable }}>
                      { (row.trasferta==="1") ? ((row.km*row.rimborsoKM) ? parseFloat(row.km*row.rimborsoKM).toFixed(2) : "") : "" }
										</TableCell>
                    <TableCell align="right" style={{ fontSize: this.state.fontSizeTable }}>
                      { (row.trasferta==="1") ? (row.pedaggio ? parseFloat(row.pedaggio).toFixed(2)+" ("+(row.pedaggio/2).toFixed(2)+"x2)" : "") : "" }
										</TableCell>
										<TableCell align="right" style={{ fontSize: this.state.fontSizeTable }}>
                      { (this.state.rimborsiEdit.id!==row.id) && row.costiExtra ? parseFloat(row.costiExtra).toFixed(2) : "" }
                      { (this.state.rimborsiEdit.id===row.id) && <TextField id="costiExtra" name="costiExtra" value={ row.costiExtra } type="number" onChange={ (e) => this.handleInputChange(e, row) } InputProps={{ style: { fontSize: '10px', } }} /> }
										</TableCell>
										<TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
                      { (this.state.rimborsiEdit.id!==row.id) && row.descrizioneCostiExtra }
                      { (this.state.rimborsiEdit.id===row.id) && <TextField id="descrizioneCostiExtra" name="descrizioneCostiExtra" value={ row.descrizioneCostiExtra } type="text" onChange={ (e) => this.handleInputChange(e, row) } InputProps={{ style: { fontSize: '10px', } }} /> }
										</TableCell>
										<TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
                      { (row.trasferta==="1") ? <Check /> : "" }
										</TableCell>
										<TableCell align="right" style={{ fontSize: this.state.fontSizeTable }}>
                      { (row.trasferta==="1") ? parseFloat( row.km*row.rimborsoKM + (row.pedaggio ? parseFloat(row.pedaggio) : 0) + ( row.costiExtra ? parseFloat(row.costiExtra) : 0 ) ).toFixed(2) : "" }
										</TableCell>
										<TableCell align="left" style={{ fontSize: this.state.fontSizeTable }}>
											{ (this.state.rimborsiEdit.id!==row.id) && <ButtonGroup><Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={ () => this.handleModifica(row) } ><Pencil /></Button><Button disabled><Cancel /></Button></ButtonGroup> }
											{ (this.state.rimborsiEdit.id===row.id) &&
												<ButtonGroup>
													<Button variant="contained" className={this.props.classes.salva} onClick={ () => this.handleSave(row.id) } ><ContentSave /></Button>
                          <Button variant="contained" className={this.props.classes.annulla} onClick={ () => this.handleCancel(row.id) } ><Cancel /></Button>
												</ButtonGroup >
											}
										</TableCell>
		              </TableRow>
		            ))
            }
            <TableRow>
              <TableCell align="left">TOTALI:</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="right">{ !isNaN(this.state.totaleKM) ? this.state.totaleKM : parseFloat("0").toFixed(2) } km</TableCell>
              <TableCell align="right">€ { !isNaN(this.state.totaleRimborsiKM) ? this.state.totaleRimborsiKM : parseFloat("0").toFixed(2) }</TableCell>
              <TableCell align="right">€ { !isNaN(this.state.totalePedaggi) ? this.state.totalePedaggi : parseFloat("0").toFixed(2) }</TableCell>
              <TableCell align="right">€ { !isNaN(this.state.totaleCostiEXTRA) ? this.state.totaleCostiEXTRA : parseFloat("0").toFixed(2) }</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="right">€ { !isNaN(this.state.totaleRimborsi) ? this.state.totaleRimborsi : parseFloat("0").toFixed(2) }</TableCell>
							<TableCell align="left"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </main>
    </div>);
  }
}

export default withStyles(styles)(Rimborsi);
