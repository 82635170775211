import React from 'react';
import { apiGet, apiPut, apiDelete } from './api';
import { FormGroup, FormControl, InputLabel, TextField } from '@material-ui/core';
import { Button, Grid, Select, MenuItem } from '@material-ui/core';
import { nullZLS } from './dati.js';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton  } from '@material-ui/core';
import { Plus } from 'mdi-material-ui'
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles.js';

const drawerWidth = 250;

class Dispositivi extends React.Component {
	state={
		dispositivi_original:{},
		dispositivi:{
			id:'',
			tipo:'',
			serialNumber:'',
			partNumber:'',
			modello:'',
			cliente:'',
			utente:'',
			note:'',
			dataVendita:'',
			dataPrimaVendita:'',
			rigafattura:'',
		},
		filtro:{
			filtro_ricerca:'',
		},
		dispostivi_tipi:[],
		anagrafiche:[],
		dispositivi_elenco:[],
	}

	caricaElencoDispositivi()	{
		apiGet("/dispositivi/elenco", (data)=>{
			this.setState({dispositivi_elenco:data});
		});
	}

	componentDidMount() {
		this.apriDettagli();
		apiGet("/anagrafiche", (data) => {
			this.setState({anagrafiche:data});
		});
		apiGet("/dispositivi/tipi", (data) => {
			this.setState({dispostivi_tipi:data});
		});
		this.caricaElencoDispositivi();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var dispositivi = this.state.dispositivi;
		dispositivi[name] = value;
		this.setState({	dispositivi:dispositivi });
	}

	handleInputChangeFiltro = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var filtro = this.state.filtro;
		filtro[name] = value;
		this.setState({	filtro:filtro });
	}

	handleCancel = () => {
		var dispositivi_original=Object.assign({},this.state.dispositivi_original);
		this.setState({ dispositivi:dispositivi_original });
	}

	handleSave = () => {
		var dispositivi={
			id:nullZLS(this.state.dispositivi.id),
			tipo:nullZLS(this.state.dispositivi.tipo),
			serialNumber:nullZLS(this.state.dispositivi.serialNumber),
			partNumber:nullZLS(this.state.dispositivi.partNumber),
			modello:nullZLS(this.state.dispositivi.modello),
			cliente:nullZLS(this.state.dispositivi.cliente),
			utente:nullZLS(this.state.dispositivi.utente),
			note:nullZLS(this.state.dispositivi.note),
			dataVendita:nullZLS(this.state.dispositivi.dataVendita),
			dataPrimaVendita:nullZLS(this.state.dispositivi.dataPrimaVendita),
			rigafattura:nullZLS(this.state.dispositivi.rigafattura),
		};

		apiPut("/dispositivi", JSON.stringify(dispositivi), (data) => {
			if (data.hasOwnProperty('errore'))	{
				alert('ERRORE: ' + data.errore);
			}	else {
				alert("Dispositivo salvato");
			}
		});
	}

	handleDelete = () => {
		if (!window.confirm("Confermare l'eliminazione del dispositivo."))
			return;
		apiDelete("/dispositivi/" + this.state.dispositivi.id, data=>{
			this.props.history.push('/dispositivi');
		});
	}

	aggiungiDispositivo() {
		var oggi = new Date();
		var oggiFormat = new moment(oggi).format('YYYY-MM-DD')
		var dispositivoNuovo={
			id:'new',
			tipo:'',
			serialNumber:'',
			partNumber:'',
			modello:'',
			cliente:'',
			utente:'',
			note:'',
			dataVendita:oggiFormat,
			dataPrimaVendita:'',
			rigafattura:'',
		}

		this.setState({dispositivi:dispositivoNuovo}, () => {
			this.dettaglioDispositivo()
		});
	}

	aggiungiNuovo = () => {
		this.aggiungiDispositivo();
	}

	apriDettagli = (id) => {
	  if ((id === 'new') || (id === undefined)) {
			var dispositivoNuovo={
				id:id,
				tipo:'',
				serialNumber:'',
				partNumber:'',
				modello:'',
				cliente:'',
				utente:'',
				note:'',
				dataVendita:'',
				dataPrimaVendita:'',
				rigafattura:'',
			}
			this.setState({dispositivi:dispositivoNuovo})
	  } else {
	    apiGet("/dispositivi/" + id, (data) => {
	      if (data.length === 0) {
	        alert("Dispositivo non trovato");
	        return;
	      }
	      var dispositivi = data[0];
				Object.keys(dispositivi).map(function(key, index) {
				  dispositivi[key] = dispositivi[key] || '';
					return true;
				});
	      var dispositivi_original = Object.assign({}, dispositivi);
	      this.setState({dispositivi: dispositivi, dispositivi_original: dispositivi_original});
	    });
	  }
	}

	dispositiviFiltrati = () => {
		var f=this.state.filtro;
		return this.state.dispositivi_elenco.filter( d => {
			var r=true;
			var dispositivi = d.serialNumber + d.partNumber;
			if (f.filtro_ricerca)	r = r && ( (dispositivi).toLowerCase().includes(f.filtro_ricerca.toLowerCase()) );
			return r;
		});
	}

	dettaglioDispositivo = () => {
		if ( (this.state.dispositivi.id) || (this.state.dispositivi.id === "new") ) {
			return (
				<form autoComplete="off" style={ { paddingTop:100 } }>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="serialNumber">Serial Number</InputLabel>
									<TextField id="serialNumber" name="serialNumber" value={this.state.dispositivi.serialNumber} type="text" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="partNumber">Part Number</InputLabel>
									<TextField id="partNumber" name="partNumber" value={this.state.dispositivi.partNumber} type="text" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="tipo">Tipologia</InputLabel>
										<Select value={this.state.dispositivi.tipo} name="tipo" id="tipo" onChange={this.handleInputChange} >
											<MenuItem value=""></MenuItem>
											{this.state.dispostivi_tipi.map((c) =>
												<MenuItem value={c.id} key={c.id}>{c.descrizioneTipo}</MenuItem>
											)}
										</Select>
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="modello">Modello</InputLabel>
									<TextField id="modello" name="modello" value={this.state.dispositivi.modello} type="text" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="cliente">Cliente</InputLabel>
										<Select value={this.state.dispositivi.cliente} name="cliente" id="cliente" onChange={this.handleInputChange} >
											<MenuItem value=""></MenuItem>
											{this.state.anagrafiche.map((c) =>
												<MenuItem value={c.id} key={c.id}>{c.denominazione}</MenuItem>
											)}
										</Select>
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="utente">Utente</InputLabel>
									<TextField id="utente" name="utente" value={this.state.dispositivi.utente} type="text" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
						</Grid>
						<br/>
						<FormGroup>
							<InputLabel htmlFor="note">Note</InputLabel>
							<TextField id="note" name="note" value={this.state.dispositivi.note} type="text" onChange={this.handleInputChange} />
						</FormGroup>
						<br/>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="dataPrimaVendita">Data PRIMA Vendita</InputLabel>
									<TextField id="dataPrimaVendita" name="dataPrimaVendita" value={this.state.dispositivi.dataPrimaVendita} type="date" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup>
									<InputLabel htmlFor="dataVendita">Data Vendita</InputLabel>
									<TextField id="dataVendita" name="dataVendita" value={this.state.dispositivi.dataVendita} type="date" onChange={this.handleInputChange} />
								</FormGroup>
							</Grid>
						</Grid>
						<br/>
						<FormGroup>
							<InputLabel htmlFor="rigafattura">Riga fattura associata</InputLabel>
							<TextField id="rigafattura" name="rigafattura" value={this.state.dispositivi.rigafattura} type="text" onChange={this.handleInputChange} />
						</FormGroup>
						<br/><br/>
							<Grid container spacing={3} style={{ position: "sticky", bottom:0, backgroundColor: "white" }}>
								<Grid item xs={4}>
									<Button variant="contained" color="secondary" fullWidth={true} onClick={this.handleDelete} >Elimina</Button>
								</Grid>
								<Grid item xs={4}>
									<Button variant="contained" color="default" fullWidth={true} onClick={this.handleCancel} >Annulla</Button>
								</Grid>
								<Grid item xs={4}>
									<Button variant="contained" color="primary" fullWidth={true} onClick={this.handleSave} >Salva</Button>
								</Grid>
							</Grid>
				</form>
			);
		}
		return (
			<div>
				<h2>Selezionare un dispositivo a fianco.</h2>
			</div>
		)
	}

	render ()	{
		return (
			<div style={ { display: 'flex' } }>
				<Drawer
					style={ { width: drawerWidth, flexShrink: 0 } }
					variant="permanent"
				>
					<List style={ { paddingTop:100, width: drawerWidth } }>
					<Grid container>
						<Grid item xs>
							<FormControl style={ { margin:10 } } fullWidth>
								<TextField id="filtro_ricerca" name="filtro_ricerca" value={this.state.filtro.filtro_ricerca} label="Serial o Part Number" placeholder="Serial o Part Number" InputLabelProps={{ shrink: true, }} type="text" onChange={this.handleInputChangeFiltro} />
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<IconButton className={this.props.classes.inserisci} size="small" variant="outlined" style={{ margin: 10, }} onClick={ ()=>{ this.aggiungiNuovo() } }><Plus/></IconButton>
						</Grid>
					</Grid>
					{
						this.dispositiviFiltrati().map(d => {
							return (
								<ListItem button key={d.id} onClick={ ()=>{ this.apriDettagli(d.id) } }>
									<ListItemText primary={ d.serialNumber+" / "+d.partNumber } secondary={ "di "+d.denominazione } />
								</ListItem>
							)
						}
						)
					}
					</List>
				</Drawer>
      <main style={ { flexGrow: 1, padding: 5 } }>
				<div>
					{ this.dettaglioDispositivo() }
				</div>
			</main>
			</div>
		);
	}
}

export default withStyles(styles)(Dispositivi);
