/*import React, { useState, useEffect } from 'react';
impo/rt useInput from './useInput'
import { apiGet } from './api';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Select, MenuItem, IconButton } from '@material-ui/core';
import moment from 'moment';
import AutoComplete from './AutoComplete';
// import { Cancel, ContentSave } from 'mdi-material-ui'
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from './styles.js';
// import { nullZLS } from './dati.js';
import { Magnify } from 'mdi-material-ui'*/

export default function ContrattiScadenze(props) {
	return ("");
	/*const [ anagrafiche, setAnagrafiche, bindAnagrafiche ] = useInput([]);

	const [ select, setSelect, bindSelect ] = useInput(false);
	const [ cliente, setCliente, bindCliente ] = useInput('');
	const [ contratto, setContratto, bindContratto ] = useInput('');
	const [ oggetto, setOggetto, bindOggetto ] = useInput('');
	const [ scadenzaInizio, setScadenzaInizio, bindScadenzaInizio ] = useInput('');
	const [ scadenzaFine, setScadenzaFine, bindScadenzaFine ] = useInput('');
	const [ scadenze, setScadenze, bindScadenze ] = useInput([]);

	const filtraRicerca = () => {
		apiGet("/documenti/CTR/scadenze", dati => {
			// console.log(dati);
			setScadenze(dati);
		})
	}

	useEffect( () => {
		apiGet("/anagrafiche", dati => {
			if (dati.length === 0)
				return;
			var anagrafiche = dati.map(c=>{
				return {value:c.id, label:c.cognome+" "+c.nome};
			});
			console.log(dati);
			setAnagrafiche(dati);
		});
  });

	return (
		<div>
			<br/><br/><br/><br/><br/><br/>
				<Table size="small" padding="checkbox" >
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="left">Cliente</TableCell>
							<TableCell align="left">N° contratto</TableCell>
							<TableCell align="left" width="30%">Oggetto / Descrizione</TableCell>
							<TableCell align="left">Scadenza inizio</TableCell>
							<TableCell align="left">Scadenza fine</TableCell>
							<TableCell align="left">Fatturato</TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key="filtri" style={{ backgroundColor: 'lightgray', height: '25px', }}>
							<TableCell align="left"></TableCell>
							<TableCell align="left">
								{/* <TextField type="text" {...bindCliente} /> * /}
								<AutoComplete {...bindAnagrafiche} />
							</TableCell>
							<TableCell align="left">
								<TextField type="text" {...bindContratto} />
							</TableCell>
							<TableCell align="left">
								<TextField type="text" {...bindOggetto} />
							</TableCell>
							<TableCell align="left">
								<TextField type="date" {...bindScadenzaInizio} />
							</TableCell>
							<TableCell align="left">
								<TextField type="date" {...bindScadenzaFine} />
							</TableCell>
							<TableCell align="left" style={{ fontSize: '10px', }}>
								<Select value="" name="fatturato" id="fatturato" style={{ fontSize: '10px', }}>
									<MenuItem value="" style={{ fontSize: '10px', }}></MenuItem>
									<MenuItem value="1" key="1">Si</MenuItem>
									<MenuItem value="0" key="0">No</MenuItem>
								</Select>
							</TableCell>
							<TableCell align="left">
								<IconButton size="small" variant="outlined" style={{ margin: 10, }} onClick={filtraRicerca}><Magnify/></IconButton>
							</TableCell>
						</TableRow>
						{
							scadenze.map( (row, index) => (
								<TableRow key={row.id} style={{ height: '25px', }}>
									<TableCell align="left" style={{ fontSize: '10px', }}>
										<input type="checkbox" {...bindSelect} />
									</TableCell>
									<TableCell align="left">
										{row.descrizioneAnagrafica}
									</TableCell>
									<TableCell align="left">
										{row.numero}/{new moment(row.data).format('YYYY')}
									</TableCell>
									<TableCell align="left" width="30%">
										{row.oggetto}
									</TableCell>
									<TableCell align="center" colspan="2">
										{new moment(row.scadenza).format('DD/MM/YYYY')}
									</TableCell>
									<TableCell align="left">
										{row.fatturato}
									</TableCell>
									<TableCell align="left">
										CREA FATTURA
									</TableCell>
								</TableRow>
							))
						}

					</TableBody>
				</Table>
		</div>);*/
}
