import React, {useState,useEffect} from 'react';
import {useInput} from '../useInput';
//import AutoComplete from '../AutoComplete';
import { Magnify } from 'mdi-material-ui';
import { FormGroup, Grid, FormControl, IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment'
import { apiGet } from '../api';

export default function LogFiltro(props) {
	const [anagrafiche,setAnagrafiche]=useState([]);
	const [da,bindDa]=useInput( moment().add(-6,"days").format("YYYY-MM-DD") ,true);
	const [a,bindA]=useInput( moment().format("YYYY-MM-DD") ,true);
	const [cliente,setCliente]=useState("");
	const [ricercaDisabled,setRicercaDisabled]=useState(true);

  useEffect(()=>{
    apiGet("/log/anagrafiche", (data)=>{
			setAnagrafiche(data);
		});
  },[]);

	useEffect(()=>{
		setRicercaDisabled(da==="" || a==="" || cliente==="" || da>a);
	},[da,a,cliente]);

  const handleChange=()=>{
		props.onChange(da,a,cliente);
	}



  /*useEffect(()=>{
    handleChange({da:da,a:a,cliente:cliente});
  },[]);*/

	return(
		<Grid container spacing={1}>
			<Grid item xs={6}>
				<FormGroup>
          <Autocomplete
            id="cliente"
            options={anagrafiche}
            getOptionLabel={option => option.cliente}
            onChange={(x,v)=>setCliente(v.cliente)}
            renderInput={params => <TextField {...params} label="Cliente" variant="outlined" />}
          />
				</FormGroup>
			</Grid>
			<Grid item xs={2}>
				<FormControl fullWidth>
					<TextField {...bindDa} label="Dalla inizio" placeholder="" InputLabelProps={{ shrink: true, }} type="date" variant="outlined" />
				</FormControl>
			</Grid>
			<Grid item xs={2}>
				<FormControl fullWidth>
					<TextField {...bindA} label="Data fine" placeholder="" InputLabelProps={{ shrink: true, }} type="date" variant="outlined" />
				</FormControl>
			</Grid>
			<Grid item xs={2}>
				<IconButton size="small" variant="outlined" style={{ margin: 10, }} onClick={handleChange} disabled={ricercaDisabled}> <Magnify/></IconButton>
			</Grid>
		</Grid>
	)

}
