import React, {useState} from 'react';
import { apiGet } from '../api';
//import { nullZLS } from './dati.js';
import moment from 'moment';
import {   Table, TableBody, TableCell, TableHead, TableRow  } from '@material-ui/core';
import LogFiltro from './LogFiltro';

//import { withStyles } from '@material-ui/core/styles';
//import { styles } from '../styles.js';



export default function Log(props) {
	const [log,setLog]=useState([]);
	const [columns,setColumns]=useState([]);

	const handleFiltroChange=(da,a,cliente) =>	{
		apiGet("/log/elenco?da="+da+"&a="+a+"&cliente="+cliente, data=>{
			setLog(data);
			var colonne=[];
			if (data.length>0)	{
				for(var d in data[0])	{
					if (d.substr(0,1)==="g") {
						const dataiso=d.substr(1,4)+"-"+d.substr(5,2)+"-"+d.substr(7,2);
						colonne.push({key:d,data:dataiso});
					}
				}
			}
			setColumns(colonne);
		})
	}

	const coloreSfondo = v => {
		if (v==="OK") return 'green';
		if (v==="KO") return 'red';
	}

	return (<div>
			<LogFiltro onChange={handleFiltroChange}/>
			<Table size="small" padding="checkbox" >
					<TableHead>
						<TableRow>
							<TableCell align="left">Apparato</TableCell>
							<TableCell align="left">Processo</TableCell>
							{columns.map( (c, index) => (
								<TableCell key={index} align="left">{new moment(c.data).format('DD/MM')}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{log.map( (row, index) => (
							<TableRow key={index} style={{ height: '25px', }}>
								<TableCell align="left" style={{ fontSize: '10px', }}>
									{row.apparato}
								</TableCell>
								<TableCell align="left" style={{ fontSize: '10px', }}>
									{row.processo}
								</TableCell>
								{columns.map( (c,ci)=> (
									<TableCell align="left" style={{ fontSize: '10px', backgroundColor: coloreSfondo(row[c.key])}} key={ci}>
										{row[c.key]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
		</div>);
}
