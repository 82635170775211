import React from 'react';
import { apiGet } from './api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function formatNumber(num) {
	if (num==="")	return "";
	return 	parseFloat(num)
		.toFixed(0)
		.replace('.', ',')
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

}

export default class StatisticheFatturato extends React.Component {
	state={
		fatturato:[]
	}

	componentDidMount() {
		apiGet("/statistiche/fatturato/" + this.props.tipo, (fatturato)=>{
			this.setState({fatturato:fatturato});
		});
	}

	render ()	{
		return (
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Anno</TableCell>
						<TableCell align="right">1</TableCell>
						<TableCell align="right">2</TableCell>
						<TableCell align="right">3</TableCell>
						<TableCell align="right">4</TableCell>
						<TableCell align="right">5</TableCell>
						<TableCell align="right">6</TableCell>
						<TableCell align="right">7</TableCell>
						<TableCell align="right">8</TableCell>
						<TableCell align="right">9</TableCell>
						<TableCell align="right">10</TableCell>
						<TableCell align="right">11</TableCell>
						<TableCell align="right">12</TableCell>
						<TableCell align="right">Totale</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{this.state.fatturato.map( r=>(
						<TableRow key={r.anno} style={{height: 10}}>
							<TableCell><b>{r.anno}</b></TableCell>
							{//r.map( (c,i) => <TableCell align="right">{formatNumber(c)}</TableCell> )
							}
							<TableCell align="right">{formatNumber(r[1])}</TableCell>
							<TableCell align="right">{formatNumber(r[2])}</TableCell>
							<TableCell align="right">{formatNumber(r[3])}</TableCell>
							<TableCell align="right">{formatNumber(r[4])}</TableCell>
							<TableCell align="right">{formatNumber(r[5])}</TableCell>
							<TableCell align="right">{formatNumber(r[6])}</TableCell>
							<TableCell align="right">{formatNumber(r[7])}</TableCell>
							<TableCell align="right">{formatNumber(r[8])}</TableCell>
							<TableCell align="right">{formatNumber(r[9])}</TableCell>
							<TableCell align="right">{formatNumber(r[10])}</TableCell>
							<TableCell align="right">{formatNumber(r[11])}</TableCell>
							<TableCell align="right">{formatNumber(r[12])}</TableCell>
							<TableCell align="right"><b>{formatNumber(r.totale)}</b></TableCell>
						</TableRow>
					)
				)}
				</TableBody>
			</Table>
		);
	}
}
