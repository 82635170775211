import React from 'react';


export default function ConfigurazioneUtenti() {

  return (
    <div>
      Prova
    </div>
  );
}
