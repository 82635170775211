import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { BrowserRouter as Router,  Route,  Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Login from './Login';
import { isLoggedIn } from './auth';
import Anagrafiche from './Anagrafiche';
import Gettoni from './Gettoni';
import Documenti from './Documenti';
import Dispositivi from './Dispositivi';
import Log from './Log/Log';
import Rapportini from './Rapportini';
import ContrattiScadenze from './ContrattiScadenze';
import Scadenze from './Scadenze';
import Rimborsi from './Rimborsi';
import Statistiche from './Statistiche';
import Attivita from './Attivita';
import Home from './Home';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Header from './Header';
import ConfigurazioneUtenti from './ConfigurazioneUtenti';


class App extends Component {
  state = {
    mobileOpen: false,
    title: 'Gestione Sapsystems',
    modifica: false,
  };

  cambiaModifica = modifica => {
    this.setState({modifica:modifica});
  }

  cambiaTitolo = (titolo) =>	{
	  this.setState({ title: titolo });
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes } = this.props;

  	if (!isLoggedIn()) {
  		return <Login />
  	}
      return (
  		<Router>
        <MuiThemeProvider theme={theme}>
        <div style={ {display: 'flex' } }>
          <AppBar position="fixed" style={ { zIndex: 1400 } }>
            <Toolbar style={ { backgroundColor: "#FFFFFF", padding: 0, display: 'initial' } }>
              <Header />
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
          <br/><br/><br/><br/><br/><br/>
          <Switch>
            <Route path="/login" exact render={(props) => <Login {...props}  />} />
            <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route path="/anagrafiche" exact render={(props) => <Anagrafiche {...props} />} />
            <Route path="/gettoni" exact render={(props) => <Gettoni {...props} />} />
            <Route path="/documenti/:tipo" exact render={(props) => <Documenti {...props} onModifica={this.cambiaModifica} modifica={this.state.modifica} />} />
            <Route path="/documenti/:tipo/scadenze" exact render={(props) => <ContrattiScadenze {...props} />} />
            <Route path="/dispositivi" exact render={(props) => <Dispositivi {...props} />} />
            <Route path="/rapportini" exact render={(props) => <Rapportini {...props} />} />
            <Route path="/rapportini/:id" exact render={(props) => <Rapportini {...props} />} />
            <Route path="/scadenze" exact render={(props) => <Scadenze {...props} />} />
            <Route path="/rimborsi" exact render={(props) => <Rimborsi {...props} />} />
            <Route path="/statistiche" exact render={(props) => <Statistiche {...props} />} />
            <Route path="/log" exact render={(props) => <Log {...props} />} />
            <Route path="/attivita" exact render={(props) => <Attivita {...props} />} />

            <Route path="/configurazione/utenti" exact render={(props) => <ConfigurazioneUtenti {...props} />} />
          </Switch>
          </main>
        </div>
        </MuiThemeProvider>
  		</Router>
      );
  }

}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);
