import React, {useEffect, useState} from 'react';
import { apiGet } from './api';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton } from '@material-ui/core';
import moment from 'moment';
import { Magnify } from 'mdi-material-ui';
import {useInput} from './useInput';

function Scadenze() {
	const [documento, bindDocumento]=useInput('',true);
	const [dataDocumentoDa, bindDataDocumentoDa]=useInput('',true);
	const [dataDocumentoA, bindDataDocumentoA]=useInput('',true);
	const [cliente, bindCliente]=useInput('',true);
	const [dataScadenzaDa, bindDataScadenzaDa]=useInput( moment().startOf('month').format('YYYY-MM-DD') ,true);
	const [dataScadenzaA, bindDataScadenzaA]=useInput( moment().endOf('month').format('YYYY-MM-DD') ,true);
	const [importoDa, bindImportoDa]=useInput('',true);
	const [importoA, bindImportoA]=useInput('',true);
	const [metodoPrevisto, bindMetodoPrevisto]=useInput('',true);
	const [scadenze, setScadenze]=useState([]);
	const [totale, setTotale]=useState([]);

	const caricaElencoScadenze=()=> {
		var condizioni=[];

		if (documento) condizioni.push("numero=" + documento);
		if (cliente) condizioni.push("denominazione=%" + cliente + "%|like");
		if (dataScadenzaDa || dataScadenzaA) condizioni.push("dataScadenza="+dataScadenzaDa+","+dataScadenzaA+"|between");
		if (importoDa || importoA) condizioni.push("importo="+importoDa+","+importoA+"|between");
		if (metodoPrevisto) condizioni.push("descrizione=%" + metodoPrevisto + "%|like");
		if (dataDocumentoDa || dataDocumentoA) condizioni.push("data="+dataDocumentoDa+","+dataDocumentoA+"|between");
		var url = "/documenti/scadenze2?"+ condizioni.join("&");

		apiGet(url, data=>{
			setScadenze(data);
		});
	}

	useEffect(()=>{
		var t=0;
		scadenze.forEach(s=> {
			t+=parseFloat(s.importo);
		});
		setTotale(t.toFixed(2));
	},[scadenze]);

	useEffect(()=>{
		caricaElencoScadenze();
	})

	return (
		<div style={ { display: 'flex' } }>
    <main style={ { flexGrow: 1, } }>
				<Table size="small" padding="checkbox" >
	          <TableHead>
	            <TableRow>
								<TableCell  style={{width: '10%'}}>Tipo</TableCell>
	              <TableCell  style={{width: '10%'}}>N°</TableCell>
	              <TableCell  style={{width: '10%'}}>Data</TableCell>
	              <TableCell  style={{width: '40%'}}>Nome cliente</TableCell>
	              <TableCell  style={{width: '10%'}}>Scadenza</TableCell>
								<TableCell  style={{width: '10%'}}>Importo ivato</TableCell>
								<TableCell  style={{width: '10%'}}>Modalità di pagamento</TableCell>
	            </TableRow>
							<TableRow key="filtri" style={{ backgroundColor: 'lightgray', height: '25px', }}>
								<TableCell>

								</TableCell>
								<TableCell>
									<TextField type="text" {...bindDocumento} fullWidth/>
								</TableCell>
								<TableCell>
									<TextField label="Data iniziale" InputLabelProps={{ shrink: true, }} type="date" {...bindDataDocumentoDa} fullWidth/>
									<TextField label="Data finale" InputLabelProps={{ shrink: true, }} type="date"  {...bindDataDocumentoA}  fullWidth/>
								</TableCell>
								<TableCell>
									<TextField  {...bindCliente}  fullWidth/>
								</TableCell>
								<TableCell>
									<TextField label="Scadenza iniziale" InputLabelProps={{ shrink: true, }} type="date" {...bindDataScadenzaDa} fullWidth/>
									<TextField label="Scadenza finale" InputLabelProps={{ shrink: true, }} type="date"  {...bindDataScadenzaA}  fullWidth/>
								</TableCell>
								<TableCell>
									<TextField label="Importo min" InputLabelProps={{ shrink: true, }} type="number" {...bindImportoDa} fullWidth/>
									<TextField label="Importo max" InputLabelProps={{ shrink: true, }} type="number"  {...bindImportoA}  fullWidth/>
								</TableCell>
								<TableCell>
									<TextField {...bindMetodoPrevisto} fullWidth/>
								</TableCell>
								<TableCell>
									<IconButton size="small" variant="outlined" style={{ margin: 10, }} onClick={ caricaElencoScadenze }><Magnify/></IconButton>
								</TableCell>
							</TableRow>
							</TableHead>
							<TableBody>
	            {scadenze.map( (row, index) => (
	              <TableRow key={row.id} style={{ height: '25px', }}>
	                <TableCell>
										{row.tipo}
									</TableCell>
									<TableCell>
										{row.numero}
									</TableCell>
	                <TableCell>
										{ row.dataDocumento && new moment(row.dataDocumento).format('DD/MM/YYYY') }
									</TableCell>
	                <TableCell>
										{row.denominazione}
									</TableCell>
	                <TableCell>
										{ row.dataScadenza && new moment(row.dataScadenza).format('DD/MM/YYYY') }
									</TableCell>
		              <TableCell align="right">
										{row.importo}
									</TableCell>
		              <TableCell>
										{row.descrizionePagamentoMetodo}
									</TableCell>
	              </TableRow>
	            ))}
							<TableRow  style={{ backgroundColor: 'lightgray', height: '25px', }}>
								<TableCell></TableCell>
								<TableCell align="right"></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell align="right">{ totale }</TableCell>
								<TableCell></TableCell>
							</TableRow>
	          </TableBody>
	        </Table>
		</main>
		</div>
	);

}

export default Scadenze;
