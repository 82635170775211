import React from 'react';
import {TextField} from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import {IconButton, Select} from '@material-ui/core';
import {Plus, DeleteForever} from 'mdi-material-ui'
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles.js';

class RapportiniRighe extends React.Component {
  state = {
    showPopup: false,
    rigaID: ''
  }

  handleClickOpenDialog = (id) => {
    this.setState({showPopup: true, rigaID: id});
  };

  handleCloseDialog = () => {
    this.setState({showPopup: false});
  };

  render() {
    return (<div style={{
        width: 'auto',
        zIndex: 9999
      }}>

      <h3>Righe <IconButton size="small" variant="outlined" className={this.props.classes.inserisci} onClick={this.props.aggiungiRiga}><Plus/></IconButton></h3>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' width='5%'>ID</TableCell>
            <TableCell align='center' width='10%'>Utente</TableCell>
            <TableCell align='center' width='10%'>Apparato</TableCell>
            <TableCell align='center' width='40%'>Descrizione</TableCell>
            <TableCell align='center' width='5%'>Esito</TableCell>
            <TableCell align='center' width='25%'>Note private</TableCell>
            <TableCell align='center' width='5%'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            this.props.righe.filter(r => r.azione !== "d").map((r, index) => {
              return (<TableRow key={r.id}>
                <TableCell className={this.props.classes.tabellaRighe}>
                  {r.id}
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <TextField name="utente" value={r.utente} type="text" onChange={(e) => this.props.onChange(e, r)} fullWidth/>
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <TextField name="apparato" value={r.apparato} type="text" onChange={(e) => this.props.onChange(e, r)} fullWidth/>
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <TextField name="descrizione" value={r.descrizione} type="text" onChange={(e) => this.props.onChange(e, r)} fullWidth/>
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <Select native value={r.esito} name="esito" onChange={(e) => this.props.onChange(e, r)} fullWidth>
                    <option value=""/>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                  </Select>
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <TextField name="notePrivate" value={r.notePrivate} type="text" onChange={(e) => this.props.onChange(e, r)} fullWidth/>
                </TableCell>
                <TableCell className={this.props.classes.tabellaRighe}>
                  <Button className={this.props.classes.elimina} onClick={() => this.props.eliminaRiga(r.id)}><DeleteForever/></Button>
                </TableCell>
              </TableRow>)
            })
          }
        </TableBody>
      </Table>
    </div>)
  }
}

export default withStyles(styles)(RapportiniRighe);
