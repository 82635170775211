import React from 'react';
import {apiGet, apiPut, apiPost, apiDelete} from './api';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  TextField,
  OutlinedInput
} from '@material-ui/core';
import {Button, Grid, FormControl, IconButton} from '@material-ui/core';
import {Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import {Plus, DeleteForever} from 'mdi-material-ui'
import {nullZLS} from './dati.js';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles.js';
import ReactList from 'react-list';
import Paper from '@material-ui/core/Paper';

const drawerWidth = 250;

class Anagrafiche extends React.Component {
  state = {
    clienti_elenco: [],
    filtro: {
      filtro_nome: ''
    },
    clienti: [],
    anagrafiche_original: {},
    anagrafiche: {},
    commerciali: [],
    pagamenti: [],
    iva: [],
    indirizzi_original: [],
    contatti_original: [],
    indirizzi: [],
    contatti: [],
    formatoTrasmissione: [],
    tipi: [],
    righe: [],
    hasError: false
  }

  caricaElencoAnagrafiche() {
    apiGet("/anagrafiche/elenco", (data) => {
      data = data.map(a => {
        if (!a.denominazione) {
          a.denominazione = "__ (Denominazione mancante)";
        }
        // a.denominazione = a.denominazione.replace(/^ +/, "*".repeat(a.denominazione.length));
        return a;
      });
      this.setState({clienti_elenco: data});
    });
  }

  componentDidMount() {
    this.apriDettagli();
    apiGet("/anagrafiche", (data) => {
      if (data.length === 0)
        return;
      var anagrafiche = data.map(c => {
        return {
          value: c.id,
          label: c.cognome + " " + c.nome
        };
      });
      this.setState({anagrafiche: anagrafiche});
    });
    apiGet("/commerciali", (data) => {
      this.setState({commerciali: data});
    });
    apiGet("/pagamenti", (data) => {
      this.setState({pagamenti: data});
    });
    apiGet("/iva", (data) => {
      this.setState({iva: data});
    });
    apiGet("/anagrafiche/tipi", (data) => {
      this.setState({tipi: data});
    });
    apiGet("/documenti/trasmissione", (data) => {
      this.setState({formatoTrasmissione: data});
    });
    this.caricaElencoAnagrafiche();
  }

  handleInputChange = (event) => {
    this.setState({hasError: false});

    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;

    if (!target.value || target.value === "") {
      this.setState({hasError: true});
    }

    var anagrafiche = this.state.anagrafiche;
    anagrafiche[name] = value;
    if (name === "nome" || name === "cognome") {
      anagrafiche['denominazione'] = this.state.anagrafiche.nome + " " + this.state.anagrafiche.cognome;
    }
    this.setState({anagrafiche: anagrafiche});
  }

  handleInputChangeFiltro = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    var filtro = this.state.filtro;
    filtro[name] = value;
    this.setState({filtro: filtro});
  }

  handleInputChangeContatto = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;

    var contatti = this.state.contatti.slice(0);
    for (var i = 0; i < contatti.length; i++) {
      if (contatti[i].id === riga.id) {
        contatti[i][name] = value;
        this.setState({contatti: contatti});
        return;
      }
    }
  }

  handleInputChangeIndirizzo = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;

    var indirizzi = this.state.indirizzi.slice(0);
    for (var i = 0; i < indirizzi.length; i++) {
      if (indirizzi[i].id === riga.id) {
        indirizzi[i][name] = value;
        this.setState({indirizzi: indirizzi});
        return;
      }
    }
  }

  handleAddContatto = () => {
    var contatti = this.state.contatti;
    var newid = 0;
    for (var i = 0; i < contatti.length; i++) {
      const id = parseInt(contatti[i].id, 10);
      if (id >= newid)
        newid = id + 1;
      }
    contatti.push({
      azione: 'n',
      id: newid,
      anagrafica: this.state.anagrafiche.id,
      nome: '',
      cognome: '',
      posizione: '',
      telefono1: '',
      telefono2: '',
      email: '',
      fax: '',
      note: '',
      fatturaCortesia: ''
    });
    this.setState({contatti: contatti});
  }

  handleAddIndirizzo = () => {
    var indirizzi = this.state.indirizzi;
    var newid = 0;
    for (var i = 0; i < indirizzi.length; i++) {
      const id = parseInt(indirizzi[i].id, 10);
      if (id >= newid)
        newid = id + 1;
      }
    indirizzi.push({
      azione: 'n',
      id: newid,
      anagrafica: this.state.anagrafiche.id,
      descrizione: '',
      numeroCivico: '',
      cap: '',
      citta: '',
      provincia: '',
      nazione: '',
      km: ''
    });
    this.setState({indirizzi: indirizzi});
  }

  handleCancel = () => {
    var anagrafiche_original = Object.assign({}, this.state.anagrafiche_original);
    this.setState({anagrafiche: anagrafiche_original});

    var indirizzi_original = Object.assign([], this.state.indirizzi_original);
    var contatti_original = Object.assign([], this.state.contatti_original);
    this.setState({indirizzi: indirizzi_original});
    this.setState({contatti: contatti_original});
  }

  handleDelete = () => {
    var id = this.state.anagrafiche.id;
    if (id === "new") {
      alert("Anagrafica nuova!");
      return;
    }

    if (!window.confirm("Confermare l'eliminazione.")) {
      return
    } else {
      apiDelete("/anagrafiche/" + id, (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
        } else {
          alert("Anagrafica eliminata");
          this.props.history.push('/anagrafiche');
        }
      });
    }
  }

  handleSave = () => {
    var anagrafica = {
      id: nullZLS(this.state.anagrafiche.id),
      nome: nullZLS(this.state.anagrafiche.nome),
      cognome: nullZLS(this.state.anagrafiche.cognome),
      titolo: nullZLS(this.state.anagrafiche.titolo),
      denominazione: nullZLS(this.state.anagrafiche.denominazione),
      tipo: nullZLS(this.state.anagrafiche.tipo),
      cf: nullZLS(this.state.anagrafiche.cf),
      pi: nullZLS(this.state.anagrafiche.pi),
      codiceEORI: nullZLS(this.state.anagrafiche.codiceEORI),
      commerciale: nullZLS(this.state.anagrafiche.commerciale),
      pagamento: nullZLS(this.state.anagrafiche.pagamento),
      iban: nullZLS(this.state.anagrafiche.iban),
      istitutoFinanziario: nullZLS(this.state.anagrafiche.istitutoFinanziario),
      abi: nullZLS(this.state.anagrafiche.abi),
      cab: nullZLS(this.state.anagrafiche.cab),
      visualizzaRapportini: nullZLS(this.state.anagrafiche.visualizzaRapportini),
      iva: nullZLS(this.state.anagrafiche.iva),
      esigibilitaIva: nullZLS(this.state.anagrafiche.esigibilitaIva),
      noteEsenzione: nullZLS(this.state.anagrafiche.noteEsenzione),
      sdiCodice: nullZLS(this.state.anagrafiche.sdiCodice),
      sdiPec: nullZLS(this.state.anagrafiche.sdiPec),
      indirizzoSedeLegale: nullZLS(this.state.anagrafiche.indirizzoSedeLegale),
      indirizzoMerce: nullZLS(this.state.anagrafiche.indirizzoMerce),
      indirizzoStabileOrganizzazione: nullZLS(this.state.anagrafiche.indirizzoStabileOrganizzazione),
      documentiFormatoTrasmissione: nullZLS(this.state.anagrafiche.documentiFormatoTrasmissione),
      dichiazioneIntento: nullZLS(this.state.anagrafiche.dichiazioneIntento),
      dichiarazioneIntentoData: nullZLS(this.state.anagrafiche.dichiarazioneIntentoData),
      prefisso: nullZLS(this.state.anagrafiche.prefisso),
      note: nullZLS(this.state.anagrafiche.note)
    };

    if (anagrafica.id === 'new') {
      apiPost("/anagrafiche", JSON.stringify(anagrafica), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
        } else {
          var id = parseInt(data, 10);
          var indirizzi = this.state.indirizzi;
          var contatti = this.state.contatti;
          indirizzi.forEach(indirizzo => {
            indirizzo.anagrafica = id;
            this.setState({indirizzi: indirizzi});
            return;
          })
          contatti.forEach(contatto => {
            contatto.anagrafica = id;
            this.setState({contatti: contatti});
            return;
          })
          apiPost("/anagrafiche/contatti", JSON.stringify(this.state.contatti), (data) => {});
          apiPost("/anagrafiche/indirizzi", JSON.stringify(this.state.indirizzi), (data) => {});
          alert("Anagrafica aggiunta");
          this.caricaElencoAnagrafiche();
        }
      });
    } else {
      apiPut("/anagrafiche", JSON.stringify(anagrafica), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
        } else {
          apiPost("/anagrafiche/contatti", JSON.stringify(this.state.contatti), (data) => {});
          apiPost("/anagrafiche/indirizzi", JSON.stringify(this.state.indirizzi), (data) => {});
          alert("Anagrafica salvata");
          this.caricaElencoAnagrafiche();
        }
      });
    }
  }

  aggiungiAnagrafica() {
    var anagraficaNuova = {
      id: 'new',
      nome: '',
      cognome: '',
      titolo: '',
      denominazione: '',
      tipo: '',
      cf: '',
      pi: '',
      codiceEORI: '',
      commerciale: '',
      pagamento: '',
      iban: '',
      istitutoFinanziario: '',
      abi: '',
      cab: '',
      visualizzaRapportini: '',
      iva: '',
      esigibilitaIva: '',
      sdiCodice: '',
      sdiPec: '',
      indirizzoSedeLegale: '',
      indirizzoMerce: '',
      indirizzoStabileOrganizzazione: '',
      documentiFormatoTrasmissione: '',
      dichiazioneIntento: '',
      dichiarazioneIntentoData: '',
      prefisso: ''
    }
    this.setState({
      anagrafiche: anagraficaNuova,
      contatti: [],
      indirizzi: []
    }, () => {
      this.dettaglioAnagrafica()
    })
  }

  aggiungiNuovo = () => {
    this.aggiungiAnagrafica();
  }

  deleteIndirizzo = (id) => {
    if (!window.confirm("Confermare l'eliminazione dell'indirizzo."))
      return;
    var indirizzi = this.state.indirizzi;
    for (var i = 0; i < indirizzi.length; i++) {
      if (indirizzi[i].id === id) {
        indirizzi[i].azione = "d";
        break;
      }
    }
    this.setState({
      indirizzi: indirizzi
    }, () => {});

  }

  deleteContatto = (id) => {
    if (!window.confirm("Confermare l'eliminazione del contatto."))
      return;
    var contatti = this.state.contatti;
    for (var i = 0; i < contatti.length; i++) {
      if (contatti[i].id === id) {
        contatti[i].azione = "d";
        break;
      }
    }
    this.setState({contatti: contatti});
  }

  apriDettagli = (id) => {
    var anagraficaVuota = {
      nome: '',
      cognome: '',
      titolo: '',
      denominazione: '',
      tipo: '',
      cf: '',
      pi: '',
      codiceEORI: '',
      commerciale: '',
      pagamento: '',
      iban: '',
      istitutoFinanziario: '',
      abi: '',
      cab: '',
      visualizzaRapportini: '',
      iva: '',
      esigibilitaIva: '',
      sdiCodice: '',
      sdiPec: '',
      indirizzoSedeLegale: '',
      indirizzoMerce: '',
      indirizzoStabileOrganizzazione: '',
      documentiFormatoTrasmissione: '',
      dichiazioneIntento: '',
      dichiarazioneIntentoData: '',
      prefisso: ''
    }
    if ((id === 'new') || (id === undefined)) {
      this.setState({anagrafiche: anagraficaVuota, contatti: [], indirizzi: []})
    } else {
      apiGet("/anagrafiche/" + id, (data) => {
        if (data.length === 0) {
          alert("Anagrafica non trovata");
          return;
        }
        var anagrafiche = data[0];
        Object.keys(anagrafiche).map(function(key, index) {
          anagrafiche[key] = anagrafiche[key] || '';
          return true;
        });
        if (anagrafiche.documentiFormatoTrasmissione === "") {
          this.setState({hasError: true});
        } else {
          this.setState({hasError: false});
        }

        if (anagrafiche.nascita_data === '0000-00-00')
          anagrafiche.nascita_data = '';
        var anagrafiche_original = Object.assign({}, anagrafiche);
        this.setState({anagrafiche: anagrafiche, anagrafiche_original: anagrafiche_original});
      });
      apiGet("/anagrafiche/" + id + "/indirizzi", (data) => {
        if (data === undefined) {} else {
          var indirizzi = [...data];
          var indirizzi_original = JSON.parse(JSON.stringify(data));
          this.setState({indirizzi: indirizzi, indirizzi_original: indirizzi_original});
        }
      });
      apiGet("/anagrafiche/" + id + "/contatti", (data) => {
        if (data === undefined) {} else {
          var contatti = [...data];
          var contatti_original = JSON.parse(JSON.stringify(data));
          this.setState({contatti: contatti, contatti_original: contatti_original});
        }
      });
    }
  }

  clientiFiltrati = () => {
    var f = this.state.filtro;
    return this.state.clienti_elenco.filter(c => {
      var r = true;
      var denominazione = c.denominazione + c.nome + c.cognome;
      if (f.filtro_nome)
        r = r && ((denominazione).toLowerCase().includes(f.filtro_nome.toLowerCase()));
      return r;
    });
  }

  dettaglioAnagrafica = () => {
    if ((this.state.anagrafiche.id !== undefined) || (this.state.anagrafiche.id === "new")) {
      return (<form autoComplete="off">
        <Grid container spacing={3}>

          <Grid item xs={1}>
            <FormGroup>
              <TextField id="id" name="id" label="ID" value={this.state.anagrafiche.id} disabled={true} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={1}>
            <FormGroup>
              <TextField id="titolo" name="titolo" label="Titolo" value={this.state.anagrafiche.titolo} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <TextField id="denominazione" name="denominazione" label="Denominazione" value={this.state.anagrafiche.denominazione} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="visualizzaRapportini">
                Visualizza Rapportini
              </InputLabel>
              <Select native value={this.state.anagrafiche.visualizzaRapportini} onChange={this.handleInputChange} input={<OutlinedInput name = "visualizzaRapportini" labelWidth = {
                  150
                }
                id = "visualizzaRapportini" />
}>
                <option value=""/>
                <option value="1">Si</option>
                <option value="0">No</option>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <TextField id="nome" name="nome" label="Nome" value={this.state.anagrafiche.nome} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <TextField id="cognome" name="cognome" label="Cognome" value={this.state.anagrafiche.cognome} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>

          <Grid item xs={2}>
            <FormGroup>
              <TextField id="cf" name="cf" label="Codice Fiscale" value={this.state.anagrafiche.cf} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <TextField id="pi" name="pi" label="Partita IVA" value={this.state.anagrafiche.pi} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <TextField id="sdiCodice" name="sdiCodice" label="SDI codice" value={this.state.anagrafiche.sdiCodice} disabled={(this.state.anagrafiche.sdiPec!=="") ? true : false} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField id="sdiPec" name="sdiPec" label="SDI Pec" value={this.state.anagrafiche.sdiPec} disabled={(this.state.anagrafiche.sdiCodice!=="") ? true : false } placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <TextField id="codiceEORI" name="codiceEORI" label="Codice EORI" value={this.state.anagrafiche.codiceEORI} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>

          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="pagamento">
                Pagamento
              </InputLabel>
              <Select native value={this.state.anagrafiche.pagamento} onChange={this.handleInputChange} input={<OutlinedInput name = "pagamento" labelWidth = {
                  100
                }
                id = "pagamento" />
}>
                <option value=""/> {this.state.pagamenti.map((t) => <option value={t.id} key={t.id}>{t.descrizione}</option>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField id="iban" name="iban" label="IBAN" value={this.state.anagrafiche.iban} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <TextField id="istitutoFinanziario" name="istitutoFinanziario" label="Istituto Finanziario" value={this.state.anagrafiche.istitutoFinanziario} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="iva">
                Aliquota IVA
              </InputLabel>
              <Select native value={this.state.anagrafiche.iva} onChange={this.handleInputChange} input={<OutlinedInput name = "iva" labelWidth = {
                  100
                }
                id = "iva" />
}>
                <option value=""/> {this.state.iva.map((t) => <option value={t.codice} key={t.codice}>{t.descrizione}</option>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="esigibilitaIva">
                Esigibilità IVA
              </InputLabel>
              <Select
                native
                value={this.state.anagrafiche.esigibilitaIva}
                onChange={this.handleInputChange}
                input={
                  <OutlinedInput name="esigibilitaIva" labelWidth={100} id="esigibilitaIva" />
                }
              >
                <option value=""></option>
                <option value="I">Esigibilità immediata</option>
                <option value="D">Esigibilità differita</option>
                <option value="S">Scissione dei pagamenti</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth error={this.state.hasError}>
              <InputLabel htmlFor="documentiFormatoTrasmissione">
                Formato Trasmissione SDI
              </InputLabel>
              <Select native value={this.state.anagrafiche.documentiFormatoTrasmissione} onChange={this.handleInputChange} input={<OutlinedInput name = "documentiFormatoTrasmissione" labelWidth = {
                  200
                }
                id = "documentiFormatoTrasmissione" />
}>
                <option value=""/> {this.state.formatoTrasmissione.map((t) => <option value={t.codice} key={t.codice}>{t.descrizione}</option>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="commerciale">
                Commerciale
              </InputLabel>
              <Select native value={this.state.anagrafiche.commerciale} onChange={this.handleInputChange} input={<OutlinedInput name = "commerciale" labelWidth = {
                  100
                }
                id = "commerciale" />
}>
                <option value=""/> {
                  this.state.commerciali.map((t) => <option value={t.id} key={t.id}>{t.nome} {t.cognome}</option>)
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormGroup>
              <TextField id="dichiazioneIntento" name="dichiazioneIntento" label="Dichiazione Intento n°" value={this.state.anagrafiche.dichiazioneIntento} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={3}>
            <FormGroup>
              <TextField id="dichiarazioneIntentoData" name="dichiarazioneIntentoData" label="Data" value={this.state.anagrafiche.dichiarazioneIntentoData} placeholder="" fullWidth margin="dense" type="date" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <TextField id="noteEsenzione" name="noteEsenzione" label="Note esenzione" value={this.state.anagrafiche.noteEsenzione} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="indirizzi">
              <h3>Indirizzi
                <IconButton size="small" variant="outlined" className={this.props.classes.inserisci} onClick={this.handleAddIndirizzo}><Plus/></IconButton>
              </h3>
            </InputLabel>
            <Table id="indirizzi">
              <TableHead>
                <TableRow>
                  <TableCell style={{
                      width: '5em'
                    }}>Descrizione</TableCell>
                  <TableCell>Indirizzo</TableCell>
                  <TableCell style={{
                      width: '3em'
                    }}>Numero.</TableCell>
                  <TableCell style={{
                      width: '5em'
                    }}>CAP</TableCell>
                  <TableCell style={{
                      width: '10em'
                    }}>Città</TableCell>
                  <TableCell style={{
                      width: '1em'
                    }}>Provincia</TableCell>
                  <TableCell style={{
                      width: '1em'
                    }}>Nazione</TableCell>
                  <TableCell style={{
                      width: '1em'
                    }}>KM</TableCell>
                  <TableCell style={{
                      width: '1em'
                    }}>Pedaggio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.indirizzi.filter(c => c.azione !== "d").map(u => {
                    return (<TableRow key={u.id}>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="descrizione" value={u.descrizione} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="indirizzo" value={u.indirizzo} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="numeroCivico" value={u.numeroCivico} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="cap" value={u.cap} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="citta" value={u.citta} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="provincia" value={u.provincia} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="nazione" value={u.nazione} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="km" value={u.km} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField fullWidth name="pedaggio" value={u.pedaggio} onChange={(e) => this.handleInputChangeIndirizzo(e, u)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <IconButton size="small" variant="outlined" className={this.props.classes.elimina} onClick={() => this.deleteIndirizzo(u.id)}><DeleteForever/></IconButton>
                      </TableCell>
                    </TableRow>);
                  })
                }
              </TableBody>
            </Table>
            <Grid container spacing={3}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="indirizzoSedeLegale">Indirizzo Sede Legale</InputLabel>
              <Select value={this.state.anagrafiche.indirizzoSedeLegale} name="indirizzoSedeLegale" onChange={this.handleInputChange}>
                <MenuItem value=""></MenuItem>
                {
                  this.state.indirizzi.map((t) => <MenuItem value={t.id} key={t.id}>{t.descrizione} - {t.indirizzo} {t.citta} ({t.provincia})</MenuItem>)
                }
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="indirizzoMerce">Destinazione</InputLabel>
              <Select value={this.state.anagrafiche.indirizzoMerce} name="indirizzoMerce" onChange={this.handleInputChange}>
                <MenuItem value=""></MenuItem>
                {
                  this.state.indirizzi.map((t) => <MenuItem value={t.id} key={t.id}>{t.descrizione} - {t.indirizzo} {t.citta} ({t.provincia})</MenuItem>)
                }
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="indirizzoStabileOrganizzazione">Indirizzo Stabile Organizzazione (solo per aziende estere)</InputLabel>
              <Select value={this.state.anagrafiche.indirizzoStabileOrganizzazione} name="indirizzoStabileOrganizzazione" id="indirizzoStabileOrganizzazione" onChange={this.handleInputChange}>
                <MenuItem value=""></MenuItem>
                {
                  this.state.indirizzi.map((t) => <MenuItem value={t.id} key={t.id}>{t.descrizione} - {t.indirizzo} {t.citta} ({t.provincia})</MenuItem>)
                }
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="contatti">
              <h3>Contatti
                <IconButton className={this.props.classes.inserisci} variant="contained" onClick={this.handleAddContatto}><Plus/></IconButton>
              </h3>
            </InputLabel>
            <Table id="contatti">
              <TableHead>
                <TableRow>
                  <TableCell style={{
                      width: '10%'
                    }}>Nome</TableCell>
                  <TableCell style={{
                      width: '10%'
                    }}>Cognome</TableCell>
                  <TableCell style={{
                      width: '10%'
                    }}>Posizione</TableCell>
                  <TableCell style={{
                      width: '10%'
                    }}>Telefono principale</TableCell>
                  <TableCell style={{
                      width: '10%'
                    }}>Telefono secondario</TableCell>
                  <TableCell style={{
                      width: '15%'
                    }}>e-mail</TableCell>
                  <TableCell style={{
                      width: '10%'
                    }}>Fax</TableCell>
                  <TableCell style={{
                      width: '8%'
                    }}>Fattura di Cortesia</TableCell>
                  <TableCell style={{
                      width: '17%'
                    }}>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.contatti.filter(c => c.azione !== "d").map(contatti => {
                    return (<TableRow key={contatti.id}>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="nome" value={contatti.nome} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="cognome" value={contatti.cognome} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="posizione" value={contatti.posizione} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="telefono1" value={contatti.telefono1} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="telefono2" value={contatti.telefono2} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="email" value={contatti.email} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="fax" value={contatti.fax} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <Select name="fatturaCortesia" native value={contatti.fatturaCortesia} fullWidth onChange={(e) => this.handleInputChangeContatto(e, contatti)}>
                          <option value=""/>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Select>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <TextField name="note" value={contatti.note} onChange={(e) => this.handleInputChangeContatto(e, contatti)}/>
                      </TableCell>
                      <TableCell style={{
                          padding: '2px'
                        }}>
                        <IconButton size="small" variant="outlined" className={this.props.classes.elimina} onClick={() => this.deleteContatto(contatti.id)}><DeleteForever/></IconButton>
                      </TableCell>
                    </TableRow>);
                  })
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <TextField id="prefisso" name="prefisso" label="Prefisso standard per righe fatture" value={this.state.anagrafiche.prefisso} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                shrink: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField id="note" name="note" label="Note" value={this.state.anagrafiche.note} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                shrink: true
              }}/>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid container spacing={3} style={{
            position: "sticky",
            bottom: 0,
            zIndex: 9999,
            backgroundColor: "white"
          }}>
          <Grid item xs={4}>
            <Button variant="contained" className={this.props.classes.elimina} fullWidth={true} onClick={this.handleDelete}>Elimina</Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={this.handleCancel}>Annulla</Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" className={this.props.classes.salva} fullWidth={true} onClick={this.handleSave}>Salva</Button>
          </Grid>
        </Grid>
      </form>);
    }
    return (<div>
      <h2>Selezionare un'anagrafica dall'elenco</h2>
    </div>)
  }

  elencoClientiItem = (index, key) => {
    const c = this.clientiFiltrati()[index];
    return (<ListItem button key={c.id} onClick={() => {
        this.apriDettagli(c.id)
      }}>
      <ListItemText primary={c.denominazione}/>
    </ListItem>)
  }

  render() {
    return (<div style={{
        display: 'flex'
      }}>
      <Drawer style={{
          width: drawerWidth,
          flexShrink: 0
        }} variant="permanent">
        <Grid container style={{
            paddingTop: 100,
            width: drawerWidth
          }}>
          <Grid item xs>
            <FormControl style={{
                margin: 10
              }} fullWidth>
              <TextField id="filtro_nome" name="filtro_nome" value={this.state.filtro.filtro_nome} label="Nome" placeholder="Nome" InputLabelProps={{
                  shrink: true
                }} type="text" onChange={this.handleInputChangeFiltro}/>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <IconButton className={this.props.classes.inserisci} size="small" variant="outlined" style={{
                margin: 10
              }} onClick={() => {
                this.aggiungiNuovo()
              }}><Plus/></IconButton>
          </Grid>
        </Grid>

        <Paper style={ {overflow: 'auto', width: drawerWidth} } elevation={0} square={true}>
          <List dense={true} disablePadding={true} style={ {width: drawerWidth, overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis'} }>
          <ReactList style={ {width: drawerWidth} }
            itemRenderer={this.elencoClientiItem}
            length={this.clientiFiltrati().length}
            type='variable'
          />
          </List>
        </Paper>

      </Drawer>
      <main style={{
          flexGrow: 1,
          padding: 5
        }}>
        <div>
          {this.dettaglioAnagrafica()}
        </div>
      </main>
    </div>)
  }
}

export default withStyles(styles)(Anagrafiche);
