import React from 'react';
import { apiGet } from './api';

export default class Home extends React.Component {

	state={
		versione: '',
	}

	componentDidMount() {
		apiGet("/versione", (data)=>{
			this.setState({versione:data});
		});
	}

	render ()	{
		return (
			<div>
      <h1>SS - Gest</h1>
			<p>Utente connesso: <strong>{ localStorage.getItem("username") }</strong></p>
			<p>Versione del: <strong>{ this.state.versione }</strong></p>
			</div>
		);
	}
}
