import { useState } from 'react';

export function useInput (initialValue,soloBind=false) {
  const [value, setValue] = useState(initialValue);
  if (soloBind) {
    return [
      value,
      {
        value: value,
        onChange: event => {
          const value =	event.target.type === 'checkbox' ? event.target.checked	: event.target.value;
          setValue(value);
        }
      }
    ];
  } else {
    return [
      value,
      setValue,
      {
        value: value,
        onChange: event => {
  				const value =	event.target.type === 'checkbox' ? event.target.checked	: event.target.value;
          setValue(value);
        }
      }
    ];
  }
};
