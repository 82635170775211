import React from 'react';
import { apiGet } from './api';
import Table from '@material-ui/core/Table';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function formatNumber(num) {
	if (num==="")	return "";
	return 	parseFloat(num)
		.toFixed(0)
		.replace('.', ',')
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

}

export default class StatisticheFatturatoCliente extends React.Component {
	state={
		fatturato:[],
		anni:[],
		orderBy:'',
		order:'asc'
	}

	componentDidMount() {
		apiGet("/statistiche/fatturato/cliente", (fatturato)=>{
			this.setState({fatturato:fatturato});
		});

		const anninew=[];
		for (var i=(new Date()).getFullYear();i>=2000;i--)	{
			anninew.push(i);
		}
		this.setState({anni:anninew});
	}

	sort(anno) {
		var order='asc';
		
		if (this.state.order==='asc' && this.state.orderBy===anno) order='desc';

		const fatturato=this.state.fatturato;
		const segno=order==='asc'?1:-1;
		fatturato.sort((a, b) => segno*a[anno] - segno*b[anno]);

		this.setState({order:order,orderBy:anno,fatturato:fatturato});
	}

	render ()	{

		return (
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Cliente</TableCell>
						{this.state.anni.map(anno=>
							<TableCell key={anno} align="right">
								<TableSortLabel active={this.state.orderBy === anno} direction={this.state.orderBy === anno ? this.state.order : 'asc'} onClick={()=>this.sort(anno)}>
									{anno}
								</TableSortLabel>
							</TableCell>
						)}
						<TableCell align="right" sortDirection='asc'>
							<TableSortLabel active={this.state.orderBy === 'totale'} direction={this.state.orderBy === 'totale' ? this.state.order : 'asc'} onClick={()=>this.sort('totale')}>
								Totale
							</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{this.state.fatturato.map( r=>(
						<TableRow key={r.denominazione} style={{height: 10}}>
							<TableCell><b>{r.denominazione}</b></TableCell>
							{//r.map( (c,i) => <TableCell align="right">{formatNumber(c)}</TableCell> )
							}
							{this.state.anni.map(anno=><TableCell key={anno} align="right">{formatNumber(r[anno])}</TableCell>)}
							<TableCell align="right"><b>{formatNumber(r.totale)}</b></TableCell>
						</TableRow>
					)
				)}
				</TableBody>
			</Table>
		);
	}
}
