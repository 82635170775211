import React from 'react';
import { apiGet, apiPut, apiDelete, apiPost } from './api';
import { nullZLS, nullZLSTabella } from './dati.js';
import { Paper, List, IconButton, ListItem, ListItemText, Drawer, FormGroup, FormControl, InputLabel, TextField, Button, Grid, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, OutlinedInput } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DocumentiRighe from './DocumentiRighe';
import DeleteForever from 'mdi-material-ui/DeleteForever'
import { Plus, Magnify, Reload } from 'mdi-material-ui';
import ReactList from 'react-list';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles.js';

const drawerWidth = 250;

class Documenti extends React.Component {
  state = {
    scadenzaPopUpOpen: false,
    scadenzaModificare: {},
    documenti_original: {},
    documenti: {},
    filtro: {
      denominazione: '',
      righe: '',
      tipo: '',
      numero: '',
      dataDa: '',
      dataA: '',
    },
    anagrafiche: [],
    indirizzi: [],
    pagamenti: [],
    pagamentiMetodo: [],
    banca: [],
    documentiTipi: [],
    tipi: [],
    righe: [],
    scadenziari: [],
    configurazione: [],
    documenti_elenco: [],
    cessioni: [],
    codicitipi: [],
    um: [],
    iva: [],
    hasError: false,
    defaultIntroduzione: '',
    defaultConclusione: '',
    totaleScadenze: 0,
    totaleDocumento: 0,
    totaleImponibileDocumento: 0,
    btnSave: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tipo !== prevProps.match.params.tipo) {
      this.apriDettagli();
      this.caricaElencoDocumenti();
    }
  }

  caricaElencoDocumenti() {
    var codiceTipo = this.props.match.params.tipo;
    var filtro = JSON.stringify(this.state.filtro)
    apiGet("/documenti/elenco/" + codiceTipo + "/" + filtro, data => {
      this.setState({ documenti_elenco: data });
    });
  }

  componentDidMount() {
    this.apriDettagli();
    apiGet("/anagrafiche", (data) => {
      if (data.length === 0)
        return;
      var anagrafiche = data.map(c => {
        return { value: c.id, label: c.denominazione };
      });
      this.setState({ anagrafiche: anagrafiche });
    });
    apiGet("/configurazione", (data) => {
      this.setState({ configurazione: data });
    });
    apiGet("/configurazione/default/Introduzione", (data) => {
      this.setState({ defaultIntroduzione: data.Introduzione });
    });
    apiGet("/configurazione/default/Conclusione", (data) => {
      this.setState({ defaultConclusione: data.Conclusione });
    });
    apiGet("/pagamenti", (data) => {
      this.setState({ pagamenti: data });
    });
    apiGet("/pagamenti/metodi", (data) => {
      this.setState({ pagamentiMetodo: data });
    });
    apiGet("/banca", (data) => {
      this.setState({ banca: data });
    });
    apiGet("/documenti/cessioni", (data) => {
      if (data.length === 0)
        return;
      var cessioni = data.map(i => {
        return { value: i.codice, label: i.descrizione };
      });
      this.setState({ cessioni: cessioni });
    });
    apiGet("/documenti/documentiTipi", (data) => {
      this.setState({ documentiTipi: data });
    });
    apiGet("/documenti/codicitipi", (data) => {
      if (data.length === 0)
        return;
      var codicitipi = data.map(i => {
        return { value: i.codiceTipo, label: i.codiceTipo };
      });
      this.setState({ codicitipi: codicitipi });
    });
    apiGet("/documenti/um", (data) => {
      if (data.length === 0)
        return;
      var um = data.map(i => {
        return { value: i.um, label: i.um };
      });
      this.setState({ um: um });
    });
    apiGet("/iva", (data) => {
      if (data.length === 0)
        return;
      var iva = data.map(i => {
        return { value: i.codice, label: i.descrizione };
      });
      this.setState({ iva: iva });
    });
    this.caricaElencoDocumenti();
  }

  calcolaTotali = () => {
    this.setState({
      totaleDocumento: 0,
      totaleImponibileDocumento: 0,
      totaleScadenze: 0,
    });
    var righe = this.state.righe;
    var imponibile = 0;
    var iva = 0;
    // Totale Documento per righe
    for (var i = 0; i < righe.length; i++) {
      imponibile += righe[i].prezzoUnitario * righe[i].quantita;
      iva += righe[i].prezzoUnitario * righe[i].quantita * righe[i].aliquota / 100;
      this.setState({
        totaleDocumento: (imponibile + iva).toFixed(2),
        totaleImponibileDocumento: imponibile.toFixed(2),
      });
    }
    var scadenze = this.state.scadenziari;
    var importoScadenze = 0;
    // Totale scadenze
    for (var j = 0; j < scadenze.length; j++) {
      importoScadenze += scadenze[j].importo * 1;
      this.setState({
        totaleScadenze: importoScadenze.toFixed(2),
      });
    }
  }

  handleAutocompleteClienteChange = (event, value, reason) => {
    var documenti=this.state.documenti;
    documenti.anagrafica=value.value;
    this.setState({ documenti: documenti }, () => {
      this.handleAggiornaAnagrafica();
    });
  }

  handleAutocompleteClienteRiferimentoChange = (event, value, reason) => {
    var documenti = this.state.documenti;
    documenti.anagraficaRiferimento = value.value;
    this.setState({documenti:documenti});
  }

  handleAggiornaAnagrafica = () => {
    var documenti = JSON.parse(JSON.stringify(this.state.documenti));
    documenti.anagraficaRiferimento = documenti.anagrafica;
    documenti.destinazione = '';
    documenti.destinatario = '';
    documenti.pagamento = '';
    documenti.firma = '';
    documenti.dichiazioneIntento = '';
    documenti.dichiarazioneIntentoData = '';
    this.setState({ documenti:documenti });
    // Recupero indirizzi relativi dopo modifica dell'anagrafica
    apiGet("/anagrafiche/" + (documenti.anagrafica || 0) + "/indirizzi", (indirizzi) => {
      if (indirizzi === undefined) {
        this.setState({ indirizzi: [] });
      } else {
        this.setState({ indirizzi: indirizzi });
      }
    });
    apiGet("/anagrafiche/" + (documenti.anagrafica || 0), anagrafica => {
      if (anagrafica === undefined || anagrafica.length === 0) {} else {
        documenti.pagamento = anagrafica[0].pagamento;
        documenti.destinatario = anagrafica[0].indirizzoSedeLegale;
        documenti.firma = anagrafica[0].firma;
        documenti.causale = anagrafica[0].noteEsenzione;
        documenti.destinazione = anagrafica[0].indirizzoMerce;
        documenti.dichiazioneIntento = anagrafica[0].dichiazioneIntento;
        documenti.dichiarazioneIntentoData = anagrafica[0].dichiarazioneIntentoData;
        if ( anagrafica[0].esigibilitaIva !== "" )
          documenti.esigibilitaIva = anagrafica[0].esigibilitaIva;
        this.setState({ documenti: documenti });
      }
    });
  }

  handleInputChange = (event) => {
    this.setState({ hasError: false });
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (!target.value || target.value === "") {
      this.setState({ hasError: true });
    }
    var documenti = this.state.documenti;
    documenti[name] = value
    this.setState({ documenti: documenti });
  }

  handleInputChangeFiltro = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var filtro = this.state.filtro;
    filtro[name] = value;
    this.setState({ filtro: filtro });
  }

  handleRigheChange = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var righe = this.state.righe.slice(0);
    for (var i = 0; i < righe.length; i++) {
      if (righe[i].id === riga.id) {
        righe[i][name] = value;
        this.setState({ righe: righe });
        return;
      }
    }
  }

  handleScadenzaChange = (event, riga) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var scadenze = this.state.scadenziari.slice(0);
    for (var i = 0; i < scadenze.length; i++) {
      if (scadenze[i].id === riga.id) {
        scadenze[i][name] = value;
        this.setState({ scadenziari: scadenze });
        return;
      }
    }
  }

  eliminaRiga = id => {
    if (!window.confirm("Confermare l'eliminazione della riga?")) return;
    var righe = this.state.righe;
    righe.forEach(riga => {
      if (riga.id === id) {
        riga.azione = "d";
        this.setState({ righe: righe });
        return;
      }
    })
  }

  aggiungiRiga = (riga = 0) => {
    apiGet("/anagrafiche/" + this.state.documenti.anagrafica, (data) => {
      var righe = this.state.righe;
      var newid = 0;
      for (var i = 0; i < righe.length; i++) {
        const id = parseInt(righe[i].id, 10);
        if (id >= newid)
          newid = id + 1;
      }
      if (riga !== 0) {
        righe.push({
          azione: 'n',
          id: newid,
          documento: riga.id,
          tipoCessionePrestazione: riga.tipoCessionePrestazione,
          codiceTipo: riga.codiceTipo,
          codiceValore: riga.codiceValore,
          descrizione: riga.descrizione,
          quantita: riga.quantita,
          um: riga.um,
          prezzoUnitario: riga.prezzoUnitario,
          dataInizioPeriodo: riga.dataInizioPeriodo,
          dataFinePeriodo: riga.dataFinePeriodo,
          iva: riga.iva,
          aliquota: riga.aliquota,
          riferimentoAmministrazione: riga.riferimentoAmministrazione
        });
      } else {
        righe.push({
          azione: 'n',
          id: newid,
          documento: this.state.documenti.id,
          tipoCessionePrestazione: '',
          codiceTipo: '',
          codiceValore: '',
          descrizione: data[0].prefisso,
          quantita: '',
          um: '',
          prezzoUnitario: '',
          dataInizioPeriodo: '',
          dataFinePeriodo: '',
          iva: data[0].iva,
          aliquota: '',
          riferimentoAmministrazione: ''
        });
      }
      this.setState({ righe: righe });
    });
  }

  aggiungiScadenza = (importo = "", dataScadenza = (new Date())) => {
    var scadenze = this.state.scadenziari;
    var newid = 0;
    for (var i = 0; i < scadenze.length; i++) {
      const id = parseInt(scadenze[i].id, 10);
      if (id >= newid)
        newid = id + 1;
    }
    const documento = this.state.documenti;
    const pagamento = this.state.pagamenti.filter(p => p.id === documento.pagamento)[0];
    scadenze.push({ azione: 'n', id: newid, documento: this.state.documenti.id, dataScadenza: dataScadenza, importo: importo, bancaPrevista: documento.banca, distinta: '', effetto: '', dataPagamento: '', bancaEffettiva: '', metodoPrevisto: pagamento.metodo, metodoEffettivo: '' });
    this.setState({ scadenze: scadenze });
  }

  handleCancel = () => {
    var documenti_original = Object.assign({}, this.state.documenti_original);
    this.setState({ documenti: documenti_original });
    this.props.onModifica(false);
  }

  handleModifica = () => {
    this.props.onModifica(true);
    return;
  }

  handleSave = () => {
    var documenti = {
      id: nullZLS(this.state.documenti.id),
      anagrafica: nullZLS(this.state.documenti.anagrafica),
      anagraficaRiferimento: nullZLS(this.state.documenti.anagraficaRiferimento),
      tipo: nullZLS(this.state.documenti.tipo),
      numero: nullZLS(this.state.documenti.numero),
      frequenza: nullZLS(this.state.documenti.frequenza),
      data: nullZLS(this.state.documenti.data),
      pagamento: nullZLS(this.state.documenti.pagamento),
      bolloVirtuale: nullZLS(this.state.documenti.bolloVirtuale),
      bolloImporto: nullZLS(this.state.documenti.bolloImporto),
      validita: nullZLS(this.state.documenti.validita),
      oggetto: nullZLS(this.state.documenti.oggetto),
      introduzione: nullZLS(this.state.documenti.introduzione),
      conclusione: nullZLS(this.state.documenti.conclusione),
      trasporto: nullZLS(this.state.documenti.trasporto),
      vettore: nullZLS(this.state.documenti.vettore),
      dataRitiro: nullZLS(this.state.documenti.dataRitiro),
      aspetto: nullZLS(this.state.documenti.aspetto),
      colli: nullZLS(this.state.documenti.colli),
      peso: nullZLS(this.state.documenti.peso),
      causale: nullZLS(this.state.documenti.causale),
      note: nullZLS(this.state.documenti.note),
      noteInterne: nullZLS(this.state.documenti.noteInterne),
      firma: nullZLS(this.state.documenti.firma),
      destinatario: nullZLS(this.state.documenti.destinatario),
      destinazione: nullZLS(this.state.documenti.destinazione),
      banca: nullZLS(this.state.documenti.banca),
      esigibilitaIva: nullZLS(this.state.documenti.esigibilitaIva),
      dichiazioneIntento: nullZLS(this.state.documenti.dichiazioneIntento),
      dichiarazioneIntentoData: nullZLS(this.state.documenti.dichiarazioneIntentoData),
    };
    if (this.state.documenti.tipo !== "OFF") {
      delete documenti.introduzione;
      delete documenti.conclusione;
    }
    if (this.state.documenti.tipo === "CTR") {
      // Genera scadenze del contratto
    }
    this.setState({ btnSave: true });
    if (documenti.id === "new") {
      documenti.id = null;
      apiPost("/documenti", JSON.stringify(documenti), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
          this.setState({ btnSave: false });
        } else {
          var id = parseInt(data, 10);
          var righe = this.state.righe;
          righe.forEach(riga => {
            riga.documento = id;
            this.setState({ righe: righe });
            this.setState({ btnSave: false });
            return;
          })
          let righeNULL = nullZLSTabella(this.state.righe);
          apiPost("/documenti/righe", JSON.stringify(righeNULL), (data) => {
            var scadenze = this.state.scadenziari;
            scadenze.forEach(scadenza => {
              scadenza.documento = id;
              this.setState({ scadenze: scadenze });
              this.setState({ btnSave: false });
              return;
            })
          });
          let scadenzeNULL = nullZLSTabella(this.state.scadenziari);
          apiPost("/documenti/" + id + "/scadenze", JSON.stringify(scadenzeNULL), (data) => {
            alert("Documento aggiunto");
            this.apriDettagli();
            this.caricaElencoDocumenti();
            this.props.onModifica(false);
            this.setState({ btnSave: false });
            return;
          });
        }
      });
    } else {
      console.log(documenti);
      apiPut("/documenti", JSON.stringify(documenti), (data) => {
        if (data.hasOwnProperty('errore')) {
          alert('ERRORE: ' + data.errore);
          this.setState({ btnSave: false });
        } else {
          var id = this.state.documenti.id;
          let righe = nullZLSTabella(this.state.righe);
          apiPost("/documenti/righe", JSON.stringify(righe), (data) => {
            let scadenze = nullZLSTabella(this.state.scadenziari);
            apiPost("/documenti/" + id + "/scadenze", JSON.stringify(scadenze), (data) => {
              alert("Documento salvato");
              this.caricaElencoDocumenti();
              this.props.onModifica(false);
              this.setState({ btnSave: false });
            });
          });
        }
      });
    }
  }

  handleDelete = () => {
    if (!window.confirm("Confermare l'eliminazione del documento (ID: " + this.state.documenti.id + ").\nVerranno eliminate anche tutte le scadenze correlate."))
      return;
    apiDelete("/documenti/" + this.state.documenti.id, data => {
      this.apriDettagli();
      this.caricaElencoDocumenti();
    });
  }

  deleteScadenza = (scadenza, quiet = false) => {
    if (!quiet)
      if (!window.confirm("Confermare l'eliminazione della scadenza."))
        return;
    var scadenziari = this.state.scadenziari;
    scadenziari.forEach(s => {
      if (s.id === scadenza.id) {
        s.azione = "d";
        this.setState({ scadenziari: scadenziari });
        return;
      }
    });
  }

  handleGeneraScadenze = () => {
    const totaleDocumento = this.state.totaleDocumento;
    const totaleImponibileDocumento = this.state.totaleImponibileDocumento;
    const documento = this.state.documenti;
    var scadenze = this.state.scadenziari;
    if (!documento.pagamento) {
      alert("Nessuno schema di pagamento impostato!");
      return;
    }
    // ATTENZIONE se documento nuovo, salvare
    if (documento.id === "new") {
      alert("ATTENZIONE: Salvare prima il documento e ricaricare per generare le scadenze!");
      return;
    }
    const scadenzePagate = this.state.scadenziari.filter(s => s.dataPagamento !== "");
    if (scadenzePagate.length > 0) {
      alert("Impossibile aggiornare automaticamente: esistono già delle scadenze pagate!");
      return;
    }
    const pagamento = this.state.pagamenti.filter(p => p.id === documento.pagamento)[0];
    scadenze.forEach(s => this.deleteScadenza(s, true));
    apiGet("/pagamenti/scadenze/" + documento.pagamento, pagamentoScadenze => {
      var importoRata;
      if (this.state.documenti.esigibilitaIva === "S") {
        importoRata = totaleImponibileDocumento / pagamentoScadenze.length;
      } else {
        importoRata = totaleDocumento / pagamentoScadenze.length;
      }
      const dataDocumento = new Date(documento.data);
      pagamentoScadenze.forEach(scadenza => {
        
        var dataScadenza = new Date(documento.data);

        const giorni=parseInt(scadenza.scadenzaGiorni, 10);
        if (giorni % 30===0) {
          const mesi=giorni/30;

          var meseScadenza=dataDocumento.getMonth()+mesi;

          dataScadenza.setMonth(meseScadenza);

          while(meseScadenza>12)  {
            meseScadenza-=12;
          }

          if (meseScadenza<dataScadenza.getMonth())  {
            dataScadenza.setDate(0);
          }
        } else {
          dataScadenza.setDate(dataScadenza.getDate() + giorni);
        }

        // Fine mese
        //
        if (pagamento.fineMese === "1") {
          dataScadenza.setMonth(dataScadenza.getMonth() + 1); // aggiungo un mese
          dataScadenza.setDate(0); // imposto il giorno a 0, l'ultimo del mese precedente
        }

        // Ritardo
        if (pagamento.ritardo) {
          dataScadenza.setDate(dataScadenza.getDate() + parseInt(pagamento.ritardo, 10));
        }
        dataScadenza = new moment(dataScadenza).format('YYYY-MM-DD')
        // Metodo
        this.aggiungiScadenza(importoRata, dataScadenza);
      });
    });
  }

  openScadenze = () => {
    window.location.href = "/documenti/CTR/scadenze";
  }

  handleDuplicaDocumento = () => {
    // Funzione chiamata per duplicare i documenti
    // viene passato il tipo del documento, il documento, le righe e le scadenze
    var documenti = JSON.parse(JSON.stringify(this.state.documenti));
    var righeDuplicate = nullZLSTabella(this.state.righe);
    delete this.state.righe;
    apiGet("/anagrafiche/" + this.state.documenti.anagrafica, (data) => {
      for (var i = 0; i < righeDuplicate.length; i++) {
        righeDuplicate[i].iva = data[0].iva;
        righeDuplicate[i].dichiazioneIntento = data[0].dichiazioneIntento;
        righeDuplicate[i].dichiarazioneIntentoData = data[0].dichiarazioneIntentoData;
        this.aggiungiRiga(righeDuplicate[i]);
      }
      documenti.dichiazioneIntento = data[0].dichiazioneIntento;
      documenti.dichiarazioneIntentoData = data[0].dichiarazioneIntentoData;
      documenti.pagamento = data[0].pagamento;
      this.aggiungiDocumento(this.state.documenti.tipo, documenti, [], this.state.scadenziari);
    });
  }

  handleStampaDocumento = () => {
    var id = this.state.documenti.id;
    apiGet("/documenti/" + id + "/pdf");
  }

  handleCortesia = () => {
    var id = this.state.documenti.id;
    if (window.confirm("Confermare l'invio della mail di cortesia?")) {
      apiPost("/fattura/" + id + "/mail", "", (data) => {
        if (data.error==="200") {
          alert("Mail inviata!");
          this.apriDettagli(id);
          this.caricaElencoDocumenti();
        } else {
          alert("Invio mail con errori: "+data.error);
          return
        }
      });
    } else {
      alert("Mail NON inviata!");
      return
    }
  }

  handleFE = () => {
    var id = this.state.documenti.id;
    if (this.state.documenti.esigibilitaIva === "S") {
      if (this.state.totaleImponibileDocumento === this.state.totaleScadenze) {
        if (this.state.documenti.invioFE) {
          if (!this.state.documenti.erroreFE.indexOf("Operazione effettuata")) {
            window.confirm("Documento già inviato!");
            return;
          }
        }
        if (!window.confirm("Confermare l'invio del documento XML?"))
          return;
        apiPost("/fattura/" + id + "/invio", "", (data) => {
          this.apriDettagli(id);
          this.caricaElencoDocumenti();
        });
      } else {
        window.confirm("ATTENZIONE: Il totale delle scadenze e diverso dal totale del documento!");
        return;
      }
    } else {
      if (this.state.totaleDocumento === this.state.totaleScadenze) {
        if (this.state.documenti.invioFE) {
          if (!this.state.documenti.erroreFE.indexOf("Operazione effettuata")) {
            window.confirm("Documento già inviato!");
            return;
          }
        }
        if (!window.confirm("Confermare l'invio del documento XML?"))
          return;
        apiPost("/fattura/" + id + "/invio", "", (data) => {
          this.apriDettagli(id);
          this.caricaElencoDocumenti();
        });
      } else {
        window.confirm("ATTENZIONE: Il totale delle scadenze e diverso dal totale del documento!");
        return;
      }
    }
  }

  handleFEannulla = () => {
    var id = this.state.documenti.id;
    //
    if (!window.confirm("Confermare l'eliminazione dell'invio dell'XML?"))
      return;
    apiPost("/fattura/" + id + "/annullaFE", "", (data) => {
      this.apriDettagli(id);
      this.caricaElencoDocumenti();
    });
  }

  handleXML = () => {
    var id = this.state.documenti.id;
    apiGet("/fattura/" + id + "/xml", "", (data) => {});
  }

  boxFatturaElettronica = () => {
    if (this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC") {
      return (
        <div>
          <br/>
          Il totale del documento (IVA inclusa) è: { this.state.totaleDocumento } - Il totale dell'imponibile è: { this.state.totaleImponibileDocumento } - Il totale delle scadenze è: { this.state.totaleScadenze }
          <br/>
					<h3>Risultati invio Fattura Elettronica</h3>
					<p><i><b>Effettuato in data:</b></i> { this.state.documenti_original.invioFE }</p>
					<p><i><b>Esito:</b></i> { this.state.documenti_original.esitoFE }</p>
					<p><i><b>Descrizione:</b></i> { this.state.documenti_original.erroreFE }</p>
          <br/>
  				<h3>Risultati invio copia di cortesia</h3>
  				<p><i><b>Effettuato in data:</b></i> { this.state.documenti_original.invioCortesia }</p>
  				<p><i><b>Esito:</b></i> { this.state.documenti_original.esitoCortesia }</p>
				</div>
      )
    }
  }

  causaleDocumento = () => {
    if (this.props.match.params.tipo !== "OFF") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="causale" name="causale" label="Causale" value={this.state.documenti.causale} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      )
    }
  }

  campoFirmaDocumento = () => {
    if (this.props.match.params.tipo === "OFF") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="firma" name="firma" label="Firma" multiline value={this.state.documenti.firma} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      )
    }
  }

  scadenzarioDocumento = () => {
    if (this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC") {
      return (
        <div style={{width: 'auto'}}>
					<h3>Scadenze { this.props.modifica && <>
							<IconButton size="small" variant="outlined" className={this.props.classes.inserisci} onClick={ this.aggiungiScadenza } ><Plus/></IconButton>
							&nbsp;
							<IconButton size="small" variant="outlined" className={this.props.classes.annulla} onClick={ this.handleGeneraScadenze } ><Reload /></IconButton>
						</>
						}</h3>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={ {padding:'5px'} }>Data di scadenza</TableCell>
									<TableCell style={ {padding:'5px'} }>Banca prevista</TableCell>
									<TableCell style={ {padding:'5px'} } number="true">Importo</TableCell>
									<TableCell style={ {padding:'5px'} }>Metodo previsto</TableCell>
									<TableCell style={ {padding:'5px'} }>Metodo effettivo</TableCell>
									<TableCell style={ {padding:'5px'} }>Data di pagamento</TableCell>
									<TableCell style={ {padding:'5px'} }>Banca effettiva</TableCell>
									<TableCell style={ {padding:'5px'} }>Effetto</TableCell>
									<TableCell style={ {padding:'5px'} }>Distinta</TableCell>
									<TableCell style={ {padding:'5px'} }></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.scadenziari.filter(s => s.azione!=="d").map(c => {
									return (
										<TableRow hover key={c.id}>
											<TableCell style={ {padding:'4px'} }>
												<TextField id="dataScadenza" name="dataScadenza" value={ (c.dataScadenza==="") ? "" : new moment(c.dataScadenza).format('YYYY-MM-DD') } type="date" onChange={ (e) => this.handleScadenzaChange(e, c) } />
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<Select value={c.bancaPrevista} name="bancaPrevista" id="bancaPrevista" onChange={ (e) => this.handleScadenzaChange(e, c) } >
													<MenuItem value=""></MenuItem>
													{this.state.banca.map((t) =>
														<MenuItem value={t.id} key={t.id}>{t.descrizione}</MenuItem>
													)}
												</Select>
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<TextField id="importo" name="importo" value={c.importo} type="number" onChange={ (e) => this.handleScadenzaChange(e, c) } />
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<Select value={c.metodoPrevisto} name="metodoPrevisto" id="metodoPrevisto" onChange={ (e) => this.handleScadenzaChange(e, c) } >
													<MenuItem value=""></MenuItem>
													{this.state.pagamentiMetodo.map((p) =>
														<MenuItem value={p.codice} key={p.codice}>{p.descrizione}</MenuItem>
													)}
												</Select>
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<Select value={c.metodoEffettivo} name="metodoEffettivo" id="metodoEffettivo" onChange={ (e) => this.handleScadenzaChange(e, c) } >
													<MenuItem value=""></MenuItem>
													{this.state.pagamentiMetodo.map((p) =>
														<MenuItem value={p.codice} key={p.codice}>{p.descrizione}</MenuItem>
													)}
												</Select>
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<TextField id="dataPagamento" name="dataPagamento" value={ (c.dataPagamento==="") ? "" : new moment(c.dataPagamento).format('YYYY-MM-DD') } type="date" onChange={ (e) => this.handleScadenzaChange(e, c) } />
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<Select value={c.bancaEffettiva} name="bancaEffettiva" id="bancaEffettiva" onChange={ (e) => this.handleScadenzaChange(e, c) } >
													<MenuItem value=""></MenuItem>
													{this.state.banca.map((t) =>
														<MenuItem value={t.id} key={t.id}>{t.descrizione}</MenuItem>
													)}
												</Select>
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<TextField id="effetto" name="effetto" value={c.effetto} type="text" onChange={ (e) => this.handleScadenzaChange(e, c) } />
											</TableCell>
											<TableCell style={ {padding:'4px'} }>
												<TextField id="distinta" name="distinta" value={c.distinta} type="text" onChange={ (e) => this.handleScadenzaChange(e, c) } />
											</TableCell>
											<TableCell style={ {padding:'5px'} }>
												<IconButton size="small" variant="outlined" className={this.props.classes.elimina}  onClick={ () => this.deleteScadenza(c) } ><DeleteForever /></IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
				</div>
      );
    }
  }

  aggiungiDocumento(tipo, documento, righe = [], scadenze) {
    // Funzione per creare un nuovo documento (o duplicarlo)
    var oggi = new Date();
    var oggiFormat = new moment(oggi).format('YYYY-MM-DD')
    var documentoNuovo = {
      id: 'new',
      anagrafica: (!documento ? '' : documento.anagrafica),
      anagraficaRiferimento: (!documento ? '' : documento.anagraficaRiferimento),
      documentiTipi: 'FATT',
      tipo: tipo,
      numero: 0,
      data: oggiFormat,
      pagamento: (!documento ? '' : documento.pagamento),
      bancaPrevista: (!documento ? '' : documento.bancaPrevista),
      bancaEffettiva: (!documento ? '' : documento.bancaEffettiva),
      bolloVirtuale: (!documento ? '' : documento.bolloVirtuale),
      bolloImporto: (!documento ? '' : documento.bolloImporto),
      oggetto: (!documento ? '' : documento.oggetto),
      introduzione: (!documento ? this.state.defaultIntroduzione : documento.introduzione),
      conclusione: (!documento ? this.state.defaultConclusione : documento.conclusione),
      trasporto: (!documento ? '' : documento.trasporto),
      vettore: (!documento ? '' : documento.vettore),
      dataRitiro: (!documento ? '' : documento.dataRitiro),
      aspetto: (!documento ? '' : documento.aspetto),
      colli: (!documento ? '' : documento.colli),
      peso: (!documento ? '' : documento.peso),
      causale: (!documento ? '' : documento.causale),
      note: (!documento ? '' : documento.note),
      firma: (!documento ? '' : documento.firma),
      destinatario: (!documento ? '' : documento.destinatario),
      destinazione: (!documento ? '' : documento.destinazione),
      validita: (!documento ? '' : documento.validita),
      banca: (!documento ? '' : documento.banca),
      esigibilitaIva: (!documento ? 'I' : documento.esigibilitaIva),
      dichiazioneIntento: (!documento ? '' : documento.dichiazioneIntento),
      dichiarazioneIntentoData: (!documento ? '' : documento.dichiarazioneIntentoData),
      invioFE: '',
      esitoFE: '',
      erroreFE: '',
      invioCortesia: '',
      esitoCortesia: '',
    }
    // Setto lo stato passando il documento e le righe, passo le scadenze ma VUOTE
    this.setState({ documenti: documentoNuovo, righe: righe, scadenziari: [] }, () => {
      this.dettaglioDocumento();
      this.props.onModifica(true);
    });
  }

  apriDettagli = (id) => {
    if ((id === 'new') || (id === undefined)) {
      var documentoVuoto = {
        id: '',
        anagrafica: '',
        anagraficaRiferimento: '',
        documentiTipi: '',
        tipo: '',
        tipoDescrizione: '',
        numero: '',
        data: '',
        pagamento: '',
        bancaPrevista: '',
        bancaEffettiva: '',
        bolloVirtuale: '',
        bolloImporto: '',
        oggetto: '',
        introduzione: '',
        conclusione: '',
        trasporto: '',
        vettore: '',
        dataRitiro: '',
        aspetto: '',
        colli: '',
        peso: '',
        causale: '',
        note: '',
        firma: '',
        destinatario: '',
        destinazione: '',
        validita: '',
        banca: '',
        esigibilitaIva: 'I',
        dichiazioneIntento: '',
        dichiarazioneIntentoData: '',
        noteInterne:'',
      }
      this.setState({ documenti: documentoVuoto, righe: [], scadenziari: [] })
    } else {
      apiGet("/documenti/" + id, (data) => {
        if (data.length === 0) {
          alert("Documento non trovato");
          return;
        }
        var documenti = data[0];
        if (documenti.pagamento === "") {
          this.setState({ hasError: true });
        } else {
          this.setState({ hasError: false });
        }
        Object.keys(documenti).map(function(key, index) {
          documenti[key] = documenti[key] || '';
          return true;
        });
        var documenti_original = Object.assign({}, documenti);
        this.setState({ documenti: documenti, documenti_original: documenti_original });
        // Recupero indirizzi relativi
        apiGet("/anagrafiche/" + this.state.documenti.anagrafica + "/indirizzi", (data) => {
          if (data === undefined) {

          } else {
            var indirizzi = [...data];
            this.setState({ indirizzi: indirizzi });
          }
        });
        apiGet("/configurazione/default/Introduzione", (data) => {
          this.setState({ defaultIntroduzione: data.Introduzione });
        });
        apiGet("/configurazione/default/Conclusione", (data) => {
          this.setState({ defaultConclusione: data.Conclusione });
        });
      });
      this.setState({ righe: [] });
      apiGet("/documenti/" + id + "/righe", (data) => {
        if (data.length === 0)
          return;
        this.setState({ righe: data });
        apiGet("/documenti/" + id + "/scadenze", (data) => {
          this.setState({ scadenziari: data });
          this.calcolaTotali();
        });
      });
    }
  }

  oggettoCTR = () => {
    if (this.props.match.params.tipo === "CTR") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="oggetto" name="oggetto" label="Oggetto / Descrizione" value={this.state.documenti.oggetto} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      );
    }
  }

  frequenzaCTR = () => {
    if (this.props.match.params.tipo === "CTR") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="frequenza" name="frequenza" label="Frequenza (in mesi)" value={this.state.documenti.frequenza} placeholder="" fullWidth margin="dense" type="number" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      );
    }
  }

  introduzioneOFF = () => {
    if (this.props.match.params.tipo === "OFF") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="oggetto" name="oggetto" label="Oggetto" value={this.state.documenti.oggetto} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				<br/>
				<FormGroup>
					<TextField id="validita" name="validita" label="Validità" value={this.state.documenti.validita} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				<br/>
				<FormGroup>
					<TextField id="introduzione" name="introduzione" label="Introduzione" multiline value={ (this.state.documenti.introduzione ? this.state.documenti.introduzione : this.state.defaultIntroduzione ) } placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      );
    }
  }

  conclusioneOFF = () => {
    if (this.props.match.params.tipo === "OFF") {
      return (
        <div>
				<br/>
				<FormGroup>
					<TextField id="conclusione" name="conclusione" label="Conclusione" multiline value={ (this.state.documenti.conclusione ? this.state.documenti.conclusione : this.state.defaultConclusione ) } placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
				</FormGroup>
				</div>
      );
    }
  }

  dichiazioneIntento = () => {
    if (this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC") {
      return (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormGroup>
              <TextField id="dichiazioneIntento" name="dichiazioneIntento" label="Dichiazione Intento n°" value={this.state.documenti.dichiazioneIntento} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
          <Grid item xs={3}>
            <FormGroup>
              <TextField id="dichiarazioneIntentoData" name="dichiarazioneIntentoData" label="Data" value={this.state.documenti.dichiarazioneIntentoData} placeholder="" fullWidth margin="dense" type="date" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{
                  shrink: true
                }}/>
            </FormGroup>
          </Grid>
        </Grid>
      );
    }
  }

  scadenzeCTR = () => {
    if (this.props.match.params.tipo === "CTR") {
      return (
        <Grid item xs={12}>
          <Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={this.openScadenze} >SCADENZE CONTRATTI</Button>
        </Grid>
      );
    }
  }

  duplicaPulsante = () => {
    if (this.props.match.params.tipo !== "CTR") {
      return (
        <Grid item xs={1}>
          { !this.props.modifica && <Button variant="contained" className={this.props.classes.inserisci} fullWidth={true} onClick={this.handleDuplicaDocumento} >DUPLICA</Button> }
        </Grid>
      );
    }
  }

  datiDDT = () => {
    if (this.props.match.params.tipo === "DDT") {
      return (
        <div>
					<br/>
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="trasporto" name="trasporto" label="Trasporto" value={this.state.documenti.trasporto} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="vettore" name="vettore" label="Vettore" value={this.state.documenti.vettore} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="dataRitiro" name="dataRitiro" label="Data di ritiro" value={this.state.documenti.dataRitiro} placeholder="" fullWidth margin="dense" type="date" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
					</Grid>
					<br/>
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="aspetto" name="aspetto" label="Aspetto" value={this.state.documenti.aspetto} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="colli" name="colli" label="Colli" value={this.state.documenti.colli} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<TextField id="peso" name="peso" label="Peso" value={this.state.documenti.peso} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
							</FormGroup>
						</Grid>
					</Grid>
				</div>
      );
    }
  }

  elencoDocumentiItem = (index, key) => {
    const d = this.state.documenti_elenco[index];
    var stile = {};
    if (d.invioFE) {
      if (d.erroreFE === "OK" || d.erroreFE.indexOf("Operazione effettuata") > -1) {
        stile = { backgroundColor: 'green' };
      } else {
        stile = { backgroundColor: 'red' };
      }
    }
    return (
      <ListItem button key={d.id} onClick={ ()=>{ if (this.props.modifica === false) { this.apriDettagli(d.id) } else { alert("ATTENZIONE: sei in modifica. Non puoi cambiare documento."); } } } style={ stile }>
        <ListItemText primary={ d.descrizioneDocumento+" - "+d.descrizioneAnagrafica } />
			</ListItem>
    );
  }

  dettaglioDocumento = () => {
    const id = this.state.documenti.id;

    var anagrafica = this.state.anagrafiche.filter( a=>{ return a.value===this.state.documenti.anagrafica } );
    if (anagrafica.length===0) {
      anagrafica=null;
    } else {
      anagrafica=anagrafica[0]
    }
    var anagraficaRiferimento = this.state.anagrafiche.filter( a=>{ return a.value===this.state.documenti.anagraficaRiferimento } );
    if (anagraficaRiferimento.length===0) {
      anagraficaRiferimento=null;
    } else {
      anagraficaRiferimento=anagraficaRiferimento[0]
    }

    if (!isNaN(parseInt(id)) || id === "new") {
      return (
        <form autoComplete="off">
					<fieldset disabled={ !this.props.modifica } style={{ border:"0" }}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							{
								id === "new" &&
								<h2>Ricordarsi di SALVARE il documento.</h2>
							}
						</Grid>
					</Grid>
					<Grid container spacing={3}>
							<Grid item xs={1}>
								<FormGroup>
									<TextField id="id" name="id" label="ID" value={this.state.documenti.id} disabled={true} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{ shrink: true, }} />
								</FormGroup>
							</Grid>
							<Grid item xs={3}>
								<FormGroup>
									<TextField id="numero" name="numero" label="Numero" value={this.state.documenti.numero} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
								</FormGroup>
							</Grid>
							<Grid item xs={3}>
								<FormGroup>
									<TextField id="data" name="data" label="Data" value={this.state.documenti.data} placeholder="" fullWidth margin="dense" type="date" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
								</FormGroup>
							</Grid>
							<Grid item xs={5}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="tipo">Tipo Documento</InputLabel>
									<Select
										native
										disabled
										value={this.state.documenti.tipo}
										onChange={this.handleInputChange}
										input={
											<OutlinedInput name="tipo" labelWidth={120} id="tipo" />
										}
									>
										<option value="" />
											{this.state.documentiTipi.map((d) =>
												<option value={d.codice} key={d.codice}>{d.descrizione}</option>
											)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<br/>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormGroup>
                  <Autocomplete
                    name="anagrafica"
                    options={ this.state.anagrafiche }
                    getOptionLabel={ (option) => option.label }
                    value={ anagrafica }
                    onChange={ this.handleAutocompleteClienteChange }
                    renderInput={(params) => <TextField {...params} label="Anagrafica" variant="outlined" />}
                  />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup>
                  <Autocomplete
                    name="anagraficaRiferimento"
                    options={ this.state.anagrafiche }
                    getOptionLabel={ (option) => option.label }
                    value={ anagraficaRiferimento }
                    onChange={ this.handleAutocompleteClienteRiferimentoChange }
                    renderInput={(params) => <TextField {...params} label="Anagrafica di Riferimento" variant="outlined" />}
                  />
								</FormGroup>
							</Grid>
						</Grid>

						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="destinatario">Destinatario</InputLabel>
									<Select
										native
										value={this.state.documenti.destinatario}
										onChange={this.handleInputChange}
										input={
											<OutlinedInput name="destinatario" labelWidth={80} id="destinatario" />
										}
									>
										<option value="" />
											{this.state.indirizzi.map((i) =>
												<option value={i.id} key={i.id}>{i.descrizione} - {i.indirizzo} {i.numeroCivico} {i.cap} {i.citta} ({i.provincia})</option>
											)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="destinazione">Destinazione (lasciare vuoto se uguale a indirizzo destinatario)</InputLabel>
									<Select
										native
										value={this.state.documenti.destinazione}
										onChange={this.handleInputChange}
										input={
											<OutlinedInput name="destinazione" labelWidth={390} id="destinazione" />
										}
									>
										<option value="" />
											{this.state.indirizzi.map((i) =>
												<option value={i.id} key={i.id}>{i.descrizione} - {i.indirizzo} {i.citta} ({i.provincia})</option>
											)}
									</Select>
								</FormControl>
							</Grid>


              <Grid item xs={12}>
                <TextField inputProps={{ style: { color: "red", fontWeight: 600 } }} multiline id="noteInterne" name="noteInterne" label="Note interne" value={this.state.documenti.noteInterne} placeholder="" fullWidth margin="dense" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
              </Grid>
						</Grid>
            { this.oggettoCTR() }
            { this.frequenzaCTR() }
						{ this.introduzioneOFF() }
    				<br/>
            <div style={{ width: '800px', zIndex: '9999', overflow: 'visible' }}>
    				<DocumentiRighe tipoDoc={this.props.match.params.tipo} righe={this.state.righe} modifica={this.props.modifica} onChange={this.handleRigheChange} aggiungiRiga={ ()=> {this.aggiungiRiga()} } eliminaRiga={this.eliminaRiga} cessioni={this.state.cessioni} codicitipi={this.state.codicitipi} um={this.state.um} iva={this.state.iva} />
            </div>
            { this.conclusioneOFF() }
						<br/>
						<Grid container spacing={3}>
							<Grid item xs={2}>
								<FormGroup>
									<TextField id="bolloImporto" name="bolloImporto" label="Bollo Importo" value={this.state.documenti.bolloImporto} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
								</FormGroup>
							</Grid>
							<Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="esigibilitaIva">
										Esigibilità IVA
									</InputLabel>
									<Select
										native
                    value={this.state.documenti.esigibilitaIva}
										onChange={this.handleInputChange}
										error={this.state.hasError}
										input={
											<OutlinedInput name="esigibilitaIva" labelWidth={100} id="esigibilitaIva" />
										}
									>
										<option value=""></option>
                    <option value="I">Esigibilità immediata</option>
                    <option value="D">Esigibilità differita</option>
                    <option value="S">Scissione dei pagamenti</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="pagamento">
										Schema di pagamento
									</InputLabel>
									<Select
										native
										value={this.state.documenti.pagamento}
										onChange={this.handleInputChange}
										error={this.state.hasError}
										input={
											<OutlinedInput name="pagamento" labelWidth={160} id="pagamento" />
										}
									>
										<option value="" />
											{this.state.pagamenti.map((p) =>
												<option value={p.id} key={p.id}>{p.descrizione}</option>
											)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="banca">
									Banca d'appoggio
								</InputLabel>
								<Select
									native
									value={this.state.documenti.banca}
									onChange={this.handleInputChange}
									input={
										<OutlinedInput name="banca" labelWidth={130} id="banca" />
									}
									>
										<option value="" />
									{this.state.banca.map((t) =>
										<option value={t.id} key={t.id}>{t.descrizione}</option>
									)}
								</Select>
							</FormControl>
							</Grid>
						</Grid>
            { this.dichiazioneIntento() }
						<br/>
						{ this.scadenzarioDocumento() }
						{ this.datiDDT() }
						{ this.causaleDocumento() }
						{ this.campoFirmaDocumento() }
						<br/>
						<FormGroup>
							<TextField id="note" name="note" label="Note" value={this.state.documenti.note} placeholder="" fullWidth margin="dense" type="text" onChange={this.handleInputChange} variant="outlined" InputLabelProps={{ shrink: true, }} />
						</FormGroup>
						<br/>
						{ this.boxFatturaElettronica() }
            </fieldset>
						<br/><br/>
							<Grid container spacing={3} style={{ position: "sticky", bottom:0, backgroundColor: "white", zIndex:10 }}>
								{ this.duplicaPulsante() }
								<Grid item xs={1}>
									{ !this.props.modifica && <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleStampaDocumento} >STAMPA</Button> }
								</Grid>
								<Grid item xs={1}>
									{ !this.props.modifica && !this.state.documenti_original.invioFE && ( this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC" ) && <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleFE} >INVIO&nbsp;FE</Button> }
                  { !this.props.modifica && this.state.documenti_original.invioFE && ( this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC" ) && <Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={this.handleFEannulla} >ANNULLA INVIO FE</Button> }
                </Grid>
                <Grid item xs={1}>
									{ !this.props.modifica && ( this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC" ) && <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleCortesia} >MAIL</Button>}
								</Grid>
                <Grid item xs={1}>
									{ !this.props.modifica && ( this.props.match.params.tipo === "FATT" || this.props.match.params.tipo === "NC" ) && <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleXML} >XML</Button>}
								</Grid>
								<Grid item xs={3}></Grid>
								<Grid item xs={2}>
									{!this.props.modifica && <Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={this.handleModifica} >MODIFICA</Button>}
									{this.props.modifica && <Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={this.handleCancel} >ANNULLA</Button>}
								</Grid>
								<Grid item xs={2}>
									{!this.props.modifica && <Button variant="contained" className={this.props.classes.elimina} fullWidth={true} onClick={this.handleDelete} >ELIMINA</Button>}
									{this.props.modifica && <Button variant="contained" className={this.props.classes.salva} fullWidth={true} disabled={this.state.btnSave} onClick={this.handleSave} >SALVA</Button>}
								</Grid>
							</Grid>

				</form>
      );
    } else {
      return (
        <div>
					<h2>Selezionare un documento a fianco.</h2>
				</div>
      )
    }
  }

  render() {
    return (
      <div style={ { display: 'flex' } }>
				<Drawer
					style={ { width: drawerWidth, flexShrink: 0 } }
	        variant="permanent"
	      >
					<Grid container style={ { paddingTop:100, margin:0, width: drawerWidth } }>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={9}>
              <Button disabled={this.props.modifica} variant="contained" className={this.props.classes.inserisci} style={{ margin: 10 }} fullWidth={true} onClick={ ()=>{ this.aggiungiDocumento(this.props.match.params.tipo) } } >INSERISCI</Button>
            </Grid>
            <Grid item xs={1}>
            </Grid>

          <Accordion>
            <AccordionSummary id="ricerca-header">
              <Button variant="contained" className={this.props.classes.funzione} style={{ margin: 0, }} fullWidth={true} >RICERCA</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container style={ { padding:0, margin:0 } }>
                <Grid item xs={12}>
                  <FormControl style={ { marginTop:5 } } fullWidth>
                    <TextField id="denominazione" name="denominazione" value={this.state.filtro.denominazione} label="Denominazione o numero" placeholder="Denominazione o numero" InputLabelProps={{ shrink: true, }} type="text" onChange={this.handleInputChangeFiltro} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={ { marginTop:5 } } fullWidth>
                    <TextField id="dataDa" name="dataDa" value={this.state.filtro.dataDa} label="Dalla data:" InputLabelProps={{ shrink: true, }} type="date" onChange={this.handleInputChangeFiltro} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={ { marginTop:5 } } fullWidth>
                    <TextField id="dataA" name="dataA" value={this.state.filtro.dataA} label="alla data:" InputLabelProps={{ shrink: true, }} type="date" onChange={this.handleInputChangeFiltro} />
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <FormControl style={ { marginTop:5 } } fullWidth>
                    <TextField id="righe" name="righe" value={this.state.filtro.righe} label="Descrizione riga" placeholder="Descrizione riga" InputLabelProps={{ shrink: true, }} type="text" onChange={this.handleInputChangeFiltro} />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <IconButton disabled={this.props.modifica} className={this.props.classes.funzione} size="small" variant="outlined" style={{ margin: 10, }} onClick={ ()=>{ this.caricaElencoDocumenti() } }><Magnify/></IconButton>
                </Grid>
                { this.scadenzeCTR() }
              </Grid>
            </AccordionDetails>
          </Accordion>
          </Grid>

					<Paper style={ {overflow: 'auto', width: drawerWidth} } elevation={0} square={true}>
            <List dense={true} disablePadding={true} style={ {width: drawerWidth, overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis'} }>
            <ReactList style={ {width: drawerWidth} }
	            itemRenderer={this.elencoDocumentiItem}
	            length={this.state.documenti_elenco.length}
	            type='variable'
	          />
            </List>
					</Paper>
	      </Drawer>
      <main style={ { flexGrow: 1, padding: 5 } }>
				<div>
					{ this.dettaglioDocumento() }
				</div>
			</main>
			</div>
    );
  }
}

export default withStyles(styles)(Documenti);
