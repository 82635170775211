import React from 'react';
import { TextField, InputBase, InputLabel } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Select, IconButton } from '@material-ui/core';
import { Plus, DeleteForever, Monitor } from 'mdi-material-ui'
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles.js';

class DocumentiRighe extends React.Component {
  state = {
    showPopup: false,
    rigaID: '',
    totaleImponibile: 0,
    totaleIVA: 0,
    totale: 0
  }

  apriDispositivo = () => {
    window.open('/dispositivi');
  }

  pulsanteAggiungiDispositivo = () => {
    if (this.props.tipoDoc === "FATT") {
      return (
        <Button size="small" className={this.props.classes.funzione} onClick={ () => {this.apriDispositivo()} }><Monitor /></Button>
      )
    }
  }

  handleAutocompleteChange= (name,value,riga)=>{
    var e={target:{name:name,value:value.value,type:'autocomplete'}};
    this.props.onChange(e,riga);
  }

  handleClickOpenDialog = (id) => {
    this.setState({
      showPopup: true,
      rigaID: id,
    });
  };

  componentDidMount = () => {
    this.calcolaTotali();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.righe !== this.props.righe)
      this.calcolaTotali();
  }

  handleCloseDialog = () => {
    this.setState({ showPopup: false });
  };

  calcolaTotali = () => {
    var righe = this.props.righe;
    var imponibile = 0;
    var iva = 0;
    for (var i = 0; i < righe.length; i++) {
      imponibile += righe[i].prezzoUnitario * righe[i].quantita;
      iva += righe[i].prezzoUnitario * righe[i].quantita * righe[i].aliquota / 100;
    }
    this.setState({
      totaleImponibile: imponibile.toFixed(2),
      totaleIVA: iva.toFixed(2),
      totale: (imponibile + iva).toFixed(2),
    });
  }

  render() {
    return (
      <div style={{width: "600", zIndex: 9999, overflow: 'visible' }}>
				<h3>Righe {this.props.modifica &&
          <IconButton size="small" variant="outlined" className={this.props.classes.inserisci} onClick={this.props.aggiungiRiga} ><Plus/></IconButton>
        }</h3>
      <Table style={{ width: "200%" }}>
					<TableHead>
						<TableRow>
              <TableCell align='center'></TableCell>
							<TableCell align='center' style={{ minWidth: "700px" }}>Descrizione</TableCell>
							<TableCell align='center'>Q.tà</TableCell>
							<TableCell align='center'>U.M.</TableCell>
							<TableCell align='center'>Prezzo Cad.</TableCell>
							<TableCell align='center'>Totale</TableCell>
							<TableCell align='center' style={{ minWidth: "200px" }}>IVA</TableCell>
              <TableCell align='center'>Periodo inizio</TableCell>
              <TableCell align='center'>Periodo fine</TableCell>
              <TableCell align='center'>Tipo cessione</TableCell>
              <TableCell colSpan={2} align='center'>Codice</TableCell>
              <TableCell align='center'>Rif. Amministrazione</TableCell>
              <TableCell align='center'>CIG</TableCell>
              <TableCell align='center'>CUP</TableCell>
              <TableCell align='center'>N. ODA</TableCell>
              <TableCell align='center'>Data ODA</TableCell>
              <TableCell align='center'>N. DDT</TableCell>
              <TableCell align='center'>Data DDT</TableCell>
              <TableCell align='center'>Fornitore</TableCell>
              <TableCell align='center'>Prezzo acquisto</TableCell>
              { (this.props.tipoDoc === "OFF") ? <TableCell align='center' style={{ minWidth: "80px" }}>Gruppo</TableCell> : null }
              { (this.props.tipoDoc === "OFF") ? <TableCell align='center' style={{ minWidth: "80px" }}>Totale</TableCell> : null }
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.righe.filter(r => r.azione!=="d").map( (r,index) => {
              var um = this.props.um.filter( a=>{ return a.value===r.um } );
              if (um.length===0) {
                um=null;
              } else {
                um=um[0]
              }
              var iva = this.props.iva.filter( a=>{ return a.value===r.iva } );
              if (iva.length===0) {
                iva=null;
              } else {
                iva=iva[0]
              }
              var cessioni = this.props.cessioni.filter( a=>{ return a.value===r.cessioni } );
              if (cessioni.length===0) {
                cessioni=null;
              } else {
                cessioni=cessioni[0]
              }
              var codicitipi = this.props.codicitipi.filter( a=>{ return a.value===r.codicitipi } );
              if (codicitipi.length===0) {
                codicitipi=null;
              } else {
                codicitipi=codicitipi[0]
              }

              return (
								<TableRow key={r.id}>
                  <TableCell className={this.props.classes.tabellaRighe}>
                      {!this.props.modifica &&
                        <Button size="small" className={this.props.classes.elimina} disabled onClick={ () => this.props.eliminaRiga(r.id) } ><DeleteForever className="mdi-18px" /></Button>
                      }
                      {this.props.modifica &&
                        <Button size="small" className={this.props.classes.elimina} onClick={ () => this.props.eliminaRiga(r.id) } ><DeleteForever className="mdi-18px" /></Button>
                      }
                      { this.pulsanteAggiungiDispositivo() }
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
										<TextField fullWidth name="descrizione" multiline value={r.descrizione} type="text" onChange={ (e) => this.props.onChange(e,r) } />
									</TableCell>
									<TableCell className={this.props.classes.tabellaRighe}>
										<TextField inputProps={{ style: { textAlign: "right" } }} name="quantita" value={ r.quantita } type="number" onChange={ (e) => this.props.onChange(e,r) } />
									</TableCell>
									<TableCell className={this.props.classes.tabellaRighe}>
                    <Autocomplete
                      name="um"
                      options={ this.props.um }
                      getOptionLabel={ (option) => option.label }
                      value={ um }
                      onChange={ (e,v)=>this.handleAutocompleteChange("um",v,r) /*attenzione r sta per riga non reason //(e) => this.props.onChange(e,r)*/ }
                      autoComplete
                      includeInputInList
                      renderInput={ (params) => <TextField {...params} /> }
                    />
									</TableCell>
									<TableCell className={this.props.classes.tabellaRighe}>
										<TextField inputProps={{ style: { textAlign: "right" } }} name="prezzoUnitario" value={ r.prezzoUnitario } type="number" onChange={ (e) => this.props.onChange(e,r) } />
									</TableCell>
									<TableCell className={this.props.classes.tabellaRighe}>
                  	<TextField inputProps={{ style: { textAlign: "right" } }} name="prezzoTotale" value={ (r.quantita*r.prezzoUnitario).toFixed(2) } type="number" />
									</TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <Autocomplete
                      name="iva"
                      options={ this.props.iva }
                      getOptionLabel={ (option) => option.label }
                      value={ iva }
                      onChange={ (e,v)=>this.handleAutocompleteChange("iva",v,r) /*attenzione r sta per riga non reason //(e) => this.props.onChange(e,r)*/ }
                      autoComplete
                      includeInputInList
                      renderInput={ (params) => <TextField {...params} /> }
                    />
									</TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
										<TextField name="dataInizioPeriodo" value={r.dataInizioPeriodo} type="date" onChange={ (e) => this.props.onChange(e,r) } />
									</TableCell>
									<TableCell className={this.props.classes.tabellaRighe}>
										<TextField name="dataFinePeriodo" value={r.dataFinePeriodo} type="date" onChange={ (e) => this.props.onChange(e,r) } />
									</TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <Autocomplete
                      name="cessioni"
                      options={ this.props.cessioni }
                      getOptionLabel={ (option) => option.label }
                      value={ cessioni }
                      onChange={ (e,v)=>this.handleAutocompleteChange("cessioni",v,r) /*attenzione r sta per riga non reason //(e) => this.props.onChange(e,r)*/ }
                      autoComplete
                      includeInputInList
                      renderInput={ (params) => <TextField {...params} /> }
                    />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <Autocomplete
                      name="codicitipi"
                      options={ this.props.codicitipi }
                      getOptionLabel={ (option) => option.label }
                      value={ codicitipi }
                      onChange={ (e,v)=>this.handleAutocompleteChange("codicitipi",v,r) /*attenzione r sta per riga non reason //(e) => this.props.onChange(e,r)*/ }
                      autoComplete
                      includeInputInList
                      renderInput={ (params) => <TextField {...params} /> }
                    />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="codiceValore" value={r.codiceValore} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="riferimentoAmministrazione" value={r.riferimentoAmministrazione} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="codiceCIG" value={r.codiceCIG} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="codiceCUP" value={r.codiceCUP} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="numeroODA" value={r.numeroODA} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="dataODA" value={r.dataODA} type="date" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="numeroDDT" value={r.numeroDDT} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="dataDDT" value={r.dataDDT} type="date" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="fornitore" value={r.fornitore} type="text" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  <TableCell className={this.props.classes.tabellaRighe}>
                    <TextField name="prezzoAcquisto" value={r.prezzoAcquisto} type="number" onChange={ (e) => this.props.onChange(e,r) } />
                  </TableCell>
                  { (this.props.tipoDoc === "OFF") ?
                    <>
                    <TableCell className={this.props.classes.tabellaRighe} hidden={ this.props.tipoDoc === "FATT" ? "hidden" : false }>
                      <TextField name="gruppo" value={r.gruppo} type="number" onChange={ (e) => this.props.onChange(e,r) } />
                    </TableCell>
                    <TableCell className={this.props.classes.tabellaRighe} hidden={ this.props.tipoDoc === "FATT" ? "hidden" : false }>
                      <Select
                        name="totale"
                        native
                        value={r.totale}
                        onChange={ (e) => this.props.onChange(e,r) }
                      >
                        <option value="">Default</option>
                        <option value="1">Si</option>
                        <option value="0">No</option>
                      </Select>
                    </TableCell>
                    </>
                  : null }
								</TableRow>
							)
						})}
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell><InputLabel>Imponibile</InputLabel></TableCell>
              <TableCell className={this.props.classes.tabellaRighe}>
                <InputBase inputProps={{ style: { textAlign: "right" } }}  value={ this.state.totaleImponibile } type="number" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell><InputLabel>IVA</InputLabel></TableCell>
              <TableCell className={this.props.classes.tabellaRighe}>
                <InputBase inputProps={{ style: { textAlign: "right" } }}  value={ this.state.totaleIVA } type="number" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell><InputLabel>Totale</InputLabel></TableCell>
              <TableCell className={this.props.classes.tabellaRighe}>
                <InputBase inputProps={{ style: { textAlign: "right"} }}  value={ this.state.totale } type="number" />
              </TableCell>
            </TableRow>
					</TableBody>

				</Table>
			</div>
    )
  }
}

export default withStyles(styles)(DocumentiRighe);
