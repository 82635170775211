import React from 'react';
import { apiGet, apiPost, apiPut } from './api';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { FormGroup, Button, TextField, Grid, FormControl, IconButton } from '@material-ui/core';
import { Magnify } from 'mdi-material-ui';
import moment from 'moment';
import { nullZLS } from './dati.js';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles.js';
import ReactList from 'react-list';
import Paper from '@material-ui/core/Paper';

const drawerWidth = 250;

class Gettoni extends React.Component {
	state={
		anagrafiche: [],
		anagrafica: 0,
		filtro: '',
		gettoni: [],
		gettoniNuovi: {},
		gettoniUtilizzo: {},
		gettoniID: [],
		descrizione: [],
		showPopupInserimento: false,
		showPopupUtilizzo: false,
		checked: false,
	}

	caricaElencoAnagrafiche()	{
		apiGet("/anagrafiche/gettoni/elenco/" + this.state.filtro, (data)=>{
			data = data.map( a => {
				if (!a.denominazione)	{
					a.denominazione="__ (Denominazione mancante)";
				}
				return a;
			});
			this.setState({anagrafiche:data});
		});
	}

	componentDidMount() {
		this.apriDettagli();
		this.caricaElencoAnagrafiche();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var anagrafiche=this.state.anagrafiche;
		anagrafiche[name]=value;
		if (name==="nome" || name==="cognome") {
			anagrafiche['denominazione'] = this.state.anagrafiche.nome + " " + this.state.anagrafiche.cognome;
		}
		this.setState({	anagrafiche:anagrafiche });
	}

	handleInputChangeDialog = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var gettoniNuovi=this.state.gettoniNuovi;
		var gettoniUtilizzo=this.state.gettoniUtilizzo;
		gettoniNuovi[name]=value;
		gettoniUtilizzo[name]=value;
		this.setState({	gettoniNuovi:gettoniNuovi, gettoniUtilizzo:gettoniUtilizzo });
	}

	select = (event) => {
		if (this.state.gettoniID.includes(event)) {
			var gettoniID=this.state.gettoniID;
			gettoniID.splice( gettoniID.indexOf(event), 1 );
			this.setState({gettoniID:gettoniID},()=>{
				//console.log(this.state.gettoniID);
			});
		} else {
			const addID = this.state.gettoniID.concat(event);
			this.setState({ gettoniID:addID });
		}
	}

	annullaSelezione=()=>{
		this.setState({gettoniID:[]});
	}

	handleInputChangeFiltro = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		var filtro=this.state.filtro;
		filtro=value;
		this.setState({	filtro:filtro });
	}

	handleOpenDialog = (id) => {
		var oggi = new Date();
		var oggiFormat = new moment(oggi).format('YYYY-MM-DD');
		var gettoni = {
			dataEmissione: oggiFormat,
    };
		this.setState({
      showPopupInserimento: true,
      anagrafica: id,
			gettoniNuovi: gettoni,
    });
  };

	handleOpenDialogUtilizzo = () => {
		var oggi = new Date();
		var oggiFormat = new moment(oggi).format('YYYY-MM-DD');
		var gettoni = {
			dataUtilizzo: oggiFormat,
    };
		this.setState({
      showPopupUtilizzo: true,
			gettoniUtilizzo: gettoni,
    });
  };

	aggiungiGettoni = () => {
		const gettoni = {
			anagrafica: this.state.anagrafica,
			qta: this.state.gettoniNuovi.qta,
			dataEmissione: this.state.gettoniNuovi.dataEmissione,
			descrizione: nullZLS(this.state.gettoniNuovi.descrizione),
    };
		apiPost("/gettoni", JSON.stringify(gettoni), (data) => {
			this.handleCloseDialog();
			this.apriDettagli(this.state.anagrafica);
		});
	}

	utilizzaGettoni = () => {
		this.state.gettoniID.forEach( (item, key) => {
			var gettone = {
				azione: 'u',
				id: item,
				dataUtilizzo: nullZLS(this.state.gettoniUtilizzo.dataUtilizzo),
	    };
			apiPut("/gettoni", JSON.stringify(gettone), (data) => {
				this.annullaSelezione();
				this.handleCloseDialog();
			});
		});
	}

	handleCloseDialog = () => {
		this.setState({ showPopupInserimento: false, showPopupUtilizzo: false }, function () {
				this.apriDettagli(this.state.anagrafica);
		});
  };

	annullaUsoGettone = () => {
		if (!window.confirm("Confermare l'annullamento dell'utilizzo?"))
			return;
		this.state.gettoniID.forEach( (item, key) => {
			var gettone = {
				azione: 'u',
				id: item,
				dataUtilizzo: null,
		  };
			apiPut("/gettoni", JSON.stringify(gettone), (data) => {
				this.annullaSelezione();
				this.handleCloseDialog();
			});
		});
	}

	deleteGettone = () => {
		if (!window.confirm("Confermare l'eliminazione del gettone?"))
			return;
		this.state.gettoniID.forEach( (item, key) => {
			var gettone = {
				azione: 'd',
				id: item,
		  };
			apiPut("/gettoni", JSON.stringify(gettone), (data) => {
				this.annullaSelezione();
				this.handleCloseDialog();
			});
		});
	}

	apriDettagli = (id) => {
		this.annullaSelezione();
		if ((id === 'new') || (id === undefined)) {

		}	else {
			apiGet("/gettoni/" + id, (data)=>{
				if (data.length===0)	{
					this.setState( {anagrafica:id, gettoni:[]} );
					return;
				}
				var anagrafiche=data[0];
				Object.keys(anagrafiche).map(function(key, index) {
				  anagrafiche[key] = anagrafiche[key] || '';
					return true;
				});
				this.setState( {gettoni:data , anagrafica:id} );
			});
		}
	}

	dettaglioGettoni = () => {
		const id = this.state.anagrafica;
    if (id !== 0) {
      return (
				<form autoComplete="off">
					<Grid container spacing={3}>
							<table style={{ width: '100%', }}>
								<thead>
							  <tr>
							    <th align="left" style={{ maxWidth: '8px', }}></th>
							    <th align="left" style={{ maxWidth: '10px', }}>ID</th>
							    <th align="left" style={{ maxWidth: '25px', }}>Data emissione</th>
									<th align="left" style={{ maxWidth: '25px', }}>Lotto</th>
									<th align="left" style={{ maxWidth: '32px', }}>Numero</th>
									<th align="left" style={{ maxWidth: '25px', }}>Data utilizzo</th>
									<th align="left" style={{ maxWidth: '50px', }}>Descrizione</th>
									<th align="left">Rapportini collegati</th>
							  </tr>
								</thead>
								<tbody>
								{ this.state.gettoni.map( (row, index) => (
									<tr key={row.id} style={{ height: '15px', }}>
										<td>
											<input type="checkbox" id={ row.id } name="gettoneID" onChange={ () => { this.select(row.id)} } checked={ this.state.gettoniID.includes(row.id) } />
										</td>
										<td>{ row.id }</td>
										<td>
											{ (row.dataEmissione==="") ? "" : new moment(row.dataEmissione).format('DD/MM/YYYY') }
										</td>
										<td>{ row.lotto }</td>
										<td>{ row.numero }</td>
										<td>
											{ (row.dataUtilizzo==="") ? "" : new moment(row.dataUtilizzo).format('DD/MM/YYYY') }
										</td>
										<td>{ row.descrizione }</td>
										<td><a href={ "/rapportini/" + row.rapportini } target="_blank" rel="noopener noreferrer">{ row.tecnico }</a></td>
									</tr>
								))}
								</tbody>
							</table>
					</Grid>
						<br/><br/><br/>
						<Grid container spacing={3} style={{ position: "sticky", bottom:0, backgroundColor: "white" }}>
								<Grid item xs={3}>
									<Button variant="contained" className={this.props.classes.inserisci} fullWidth={true} onClick={ ()=>{ this.handleOpenDialog(this.state.anagrafica) } } >Inserisci</Button>
								</Grid>
								<Dialog
									onClose={this.handleCloseDialog}
									aria-labelledby="Crea gettoni"
									open={this.state.showPopupInserimento}
									maxWidth="lg"
								>
								<DialogTitle id="Crea gettoni" onClose={this.handleCloseDialog}>
									Inserisci nuovi gettoni
								</DialogTitle>
								<DialogContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<FormGroup>
												<TextField id="dataEmissione" name="dataEmissione" label="Data di emissione" type="date" onChange={this.handleInputChangeDialog} value={this.state.gettoniNuovi.dataEmissione} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{ shrink: true, }} />
											</FormGroup>
										</Grid>
									</Grid>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormGroup>
												<TextField id="qta" name="qta" label="Quantità" type="number" onChange={this.handleInputChangeDialog} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{ shrink: true, }} />
											</FormGroup>
										</Grid>
									</Grid>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<FormGroup>
												<TextField id="descrizione" name="descrizione" label="Descrizione" type="text" onChange={this.handleInputChangeDialog} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{ shrink: true, }} />
											</FormGroup>
										</Grid>
									</Grid>
								</DialogContent>
									<DialogActions>
										<Button onClick={ this.aggiungiGettoni } className={this.props.classes.salva} >INSERISCI</Button>
										<Button onClick={ this.handleCloseDialog } className={this.props.classes.annulla} >ANNULLA</Button>
									</DialogActions>
								</Dialog>
								<Grid item xs={3}>
									<Button variant="contained" className={this.props.classes.funzione} fullWidth={true} onClick={ this.handleOpenDialogUtilizzo } >Assegna utilizzo</Button>
								</Grid>
								<Dialog
									onClose={this.handleCloseDialog}
									aria-labelledby="Assegna utilizzo gettoni"
									open={this.state.showPopupUtilizzo}
									maxWidth="lg"
								>
								<DialogTitle id="Assegna utilizzo gettoni" onClose={this.handleCloseDialog}>
									Assegna utilizzo
								</DialogTitle>
								<DialogContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<FormGroup>
												<TextField id="dataUtilizzo" name="dataUtilizzo" label="Data di utilizzo" type="date" onChange={this.handleInputChangeDialog} value={this.state.gettoniUtilizzo.dataUtilizzo} placeholder="" fullWidth margin="dense" variant="outlined" InputLabelProps={{ shrink: true, }} />
											</FormGroup>
										</Grid>
									</Grid>
								</DialogContent>
									<DialogActions>
										<Button onClick={ this.utilizzaGettoni } className={this.props.classes.salva} >Utilizza</Button>
										<Button onClick={ this.handleCloseDialog } className={this.props.classes.annulla} >ANNULLA</Button>
									</DialogActions>
								</Dialog>
								<Grid item xs={3}>
									<Button variant="contained" className={this.props.classes.annulla} fullWidth={true} onClick={ ()=>{ this.annullaUsoGettone() } } >Annulla utilizzo</Button>
								</Grid>
								<Grid item xs={3}>
									<Button variant="contained" className={this.props.classes.elimina} fullWidth={true} onClick={ ()=>{ this.deleteGettone() } } >Elimina</Button>
								</Grid>
							</Grid>
				</form>
      );
    } else {
      return (
        <div>
					<h2>Selezionare un'anagrafica dall'elenco per gestirne i gettoni associati.</h2>
				</div>
      )
    }
  }

	elencoClientiItem = (index, key) => {
		const c=this.state.anagrafiche[index];
		var stile = {};
		if (c.gettoni > 0) {
      if (c.gettoniLiberi > 0) {
        stile = { backgroundColor: 'green' };
      } else {
        stile = { backgroundColor: 'red' };
      }
    } else {
			stile = { backgroundColor: 'white' };
		}
		return (
			<ListItem button key={c.id} onClick={ ()=>{ this.apriDettagli(c.id) } } style={ stile }>
				<ListItemText primary={c.denominazione} />
			</ListItem>
		)
	}

  render ()	{
		return (
    <div style={ { display: 'flex' } }>
		<Drawer
			style={ { width: drawerWidth, flexShrink: 0 } }
			variant="permanent"
		>
			<Grid container style= { { paddingTop:100, width: drawerWidth } }>
				<Grid item xs>
					<FormControl style={ { margin:10 } } fullWidth>
						<TextField id="filtro" name="filtro" value={this.state.filtro} label="Nome" placeholder="Nome" InputLabelProps={{ shrink: true, }} type="text" onChange={this.handleInputChangeFiltro} />
					</FormControl>
				</Grid>
				<Grid item xs={3}>
					<IconButton disabled={this.props.modifica} className={this.props.classes.funzione} size="small" variant="outlined" style={{ margin: 10, }} onClick={ ()=>{ this.caricaElencoAnagrafiche() } }><Magnify/></IconButton>
				</Grid>
			</Grid>

			<Paper style={ {overflow: 'auto', width: drawerWidth} } elevation={0} square={true}>
				<List dense={true} disablePadding={true} style={ {width: drawerWidth, overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis'} }>
				<ReactList style={ {width: drawerWidth} }
					itemRenderer={this.elencoClientiItem}
					length={this.state.anagrafiche.length}
					type='variable'
				/>
				</List>
			</Paper>
			
    </Drawer>
    <main style={ { flexGrow: 1, padding: 5 } }>
			<div>
				{ this.dettaglioGettoni() }
			</div>
    </main>
  </div>
	)
	}
}

export default withStyles(styles)(Gettoni);
