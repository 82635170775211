import React, {useState,useEffect} from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { apiGet/*, apiPut, apiDelete */} from './api';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'

const localizer = momentLocalizer(moment)

export default function Attivita(props) {
  const [events,setEvents]=useState([]);

  useEffect(()=>{
    apiGet("/attivita/fasi/1/2020-01-01/2020-05-01",data=>{
      const eventi=data.map((e,i)=>{return {id:i,start:new Date(e.inizio),end:new Date(e.fine),title:e.descrizione} });
      setEvents(eventi);
    })
  },[]);

  return(
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        defaultView={Views.WEEK}
        style={{ height: 800 }}
        startAccessor="start"
        endAccessor="end"
      />
    </div>);
}
